import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ColumnDataDate, ColumnDataTime } from '@ov-suite/helpers-shared';
import moment from 'moment';

// Todo: Fix Permission code on cell
// [disabled]="userPermissions[col.permissionId]
//   ? !userPermissions[col.permissionId][col.permissionType]
//   : false"

@Component({
  selector: 'ov-suite-time-cell',
  template: `
    <span *ngIf="!col.editable">
      {{ item[col?.key] }}
    </span>

    <ov-suite-input
      class="bg-dark"
      *ngIf="col.editable"
      type="time"
      [style]="{ height: '38px' }"
      [ngModel]="col.editable ? (item | displayKeyUpdate: col.key) : (item | displayKey: col.key)"
      (ngModelChange)="setValueByKey($event, col?.key, item)"
    ></ov-suite-input>
  `,
})
export class TimeCellComponent<T> {
  @Input() item: T;
  @Input() col: ColumnDataTime<T>;
  @Output() itemChange = new EventEmitter<T>();

  itemChanged(item: T): void {
    this.itemChange.emit(item);
  }

  setValueByKey(item: unknown, key: string, data: T) {
    const keys = key.split('.');
    const [firstKey] = keys;
    const tempObject = {};

    if (keys.length < 2) {
      data[firstKey] = item;
    } else {
      for (let i = 1; i < keys.length; i++) {
        tempObject[keys[keys.length - i]] = item;
        data[firstKey] = { ...data[firstKey], ...tempObject };
      }
    }
    this.itemChange.emit(data);
  }
}
