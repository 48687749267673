import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NotificationsRoutes } from "./notifications-list-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NotificationsListComponent } from "./notifications-list.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { UiModule } from "@ov-suite/ui";


@NgModule({
  imports: [
    RouterModule.forChild(NotificationsRoutes),
    CommonModule,
    NgbModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    NotificationsListComponent
  ],
  exports: [
    NotificationsListComponent
  ],
})
export class NotificationsListModule {}
