import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import {
  CompiledFieldData,
  Constructor,
  FieldParamsConstructor,
  GenericHierarchy,
  getCompiledFieldMetadata,
  getTypeMetadata,
  isFieldParamsConstructor,
  ParentLookup,
} from '@ov-suite/ov-metadata';
import { cloneDeep } from 'lodash';
import { FieldValidator } from '@ov-suite/helpers-angular';

interface DataSources<T extends GenericHierarchy = { id: number | string }> {
  [key: string]: T[] | OvAutoService | unknown;
}

@Component({
  selector: 'ov-suite-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent<T = unknown> implements OnInit {
  @Input() dataSources: DataSources<GenericHierarchy> = {};

  // @Input() ovAutoService: OvAutoService;
  @Input() sideBarMetadata;

  @Input() overrideCancel: boolean;

  @Output() cancelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() formClass: Constructor<T>;

  _data: T;

  @Input() set data(item: T) {
    this._data = item;
    this.assignDataValues();
  }

  get data() {
    return this._data;
  }

  @Input() showSave = true;

  @Output() save = new EventEmitter();

  @Output() formChange = new EventEmitter();

  metadata: CompiledFieldData[][];

  metadataMap: Record<string, CompiledFieldData> = {};

  concatenationLookup: ParentLookup[];

  hasConcatenation = false;

  showRequired = false;

  loadMap: Record<string, unknown> = {};

  onChange: Function = () => {};

  constructor(
    @Inject('DEFAULT_API') private readonly defaultApi: string,
    public readonly ovAutoService: OvAutoService,
    private readonly injector: Injector,
  ) {}

  ngOnInit() {
    this.metadata = getCompiledFieldMetadata(this.formClass);

    this.metadata.forEach(outer => {
      outer.forEach(inner => {
        this.metadataMap[inner.propertyKey] = inner;
      });
    });

    this.assignDataValues();
    this.generateConcatenationLookup();

    const generators: Function[] = [];

    this.metadata.forEach(inner => {
      inner.forEach(field => {
        if (field.generator) {
          generators.push(() => {
            field.value = field.generator(field.value, this.dataQuery);
          });
        }
      });
    });

    this.onChange = () => {
      generators.forEach(func => func());
    };
  }

  getDataSource(data: CompiledFieldData): unknown {
    if (this.dataSources && this.dataSources[data.propertyKey]) {
      return this.dataSources[data.propertyKey];
    }
    if (this.loadMap[data.propertyKey] && this.loadMap[data.propertyKey] !== 'loading') {
      return this.loadMap[data.propertyKey];
    }
    if (!this.loadMap[data.propertyKey]) {
      if (isFieldParamsConstructor(data)) {
        const dataType = data.withQuantity ? data.subType : data.type;
        this.loadMap[data.propertyKey] = 'loading';
        const { entity: remoteEntity, metadata: remoteMetadata } = getTypeMetadata(dataType);
        let remoteApi = remoteMetadata.api;
        if (!remoteApi || remoteApi === 'shared') {
          remoteApi = this.defaultApi;
        }
        this.ovAutoService
          .list({
            entity: remoteEntity,
            specifiedApi: remoteApi,
            limit: 1000,
          })
          .then(response => {
            this.loadMap[data.propertyKey] = response.data;
          });
      } else {
        return null;
      }
    }
  }

  assignDataValues() {
    if (this.data) {
      this.fieldForEach(data => {
        if (this.data[data.propertyKey]) {
          data.value = this.data[data.propertyKey];
        }
      });
    }
  }

  private fieldForEach(callback: (data: CompiledFieldData) => void) {
    this.metadata?.forEach(row => {
      row?.forEach(column => {
        callback(column);
      });
    });
  }

  private async fieldForEachAsync(callback: (data: CompiledFieldData) => void): Promise<void> {
    for (const row of this.metadata) {
      for (const column of row) {
        await callback(column);
      }
    }
  }

  getFormClass(): T {
    const output = this.data ?? new this.formClass();
    for (const row of this.metadata) {
      for (const data of row) {
        if (data.type !== 'title' && data.type !== 'blank') {
          output[data.propertyKey] = data.value;
        }
      }
    }
    return output;
  }

  async submit(noEmit?: boolean): Promise<T | undefined> {
    let requiredFlag = false;

    const { validateField } = this;

    const formClass = this.getFormClass();

    async function validate(data: CompiledFieldData): Promise<void> {
      if (data.propertyKey) {
        await validateField(data);
        if (data.danger) {
          requiredFlag = true;
        }
      }
    }

    await this.fieldForEachAsync(async data => await validate(data));

    if (!requiredFlag) {
      if (!noEmit) {
        this.save.emit(formClass);
      }
      this.showRequired = false;
      return formClass;
    }
    this.showRequired = true;
  }

  async validateArrayField(data: CompiledFieldData) {
    if (data.value) {
      this.modelChange(data);
      await this.validateField(data);
    }
  }

  dataQuery = (key: string): unknown => {
    for (const row of this.metadata) {
      for (const col of row) {
        if (col.propertyKey === key) {
          return col.value;
        }
      }
    }

    for (const row of this.sideBarMetadata) {
      for (const col of row) {
        if (col.propertyKey === key) {
          return col.value;
        }
      }
    }
  };

  validateField = async (data: CompiledFieldData) => {
    this.onChange();
    let danger = false;
    const formClass = this.getFormClass();
    if (data.validatorInjectionKey) {
      const validator: FieldValidator<T> = this.injector.get(data.validatorInjectionKey);
      if (validator.required) {
        const required = await validator.required(formClass);
        if (required) {
          if (Array.isArray(data.value) && !data.value.length) {
            danger = true;
          } else if ((data.value ?? '') === '') {
            danger = true;
          }
        }
      }
      if (validator.validate) {
        const valid = await validator.validate(formClass);
        if (!valid) {
          danger = true;
          data.currentErrorMessage = validator.errorMessage;
        }
      }
    }
    if (data.required) {
      if (Array.isArray(data.value) && !data.value.length) {
        danger = true;
      } else if ((data.value ?? '') === '') {
        danger = true;
      }
    }
    if ((<FieldParamsConstructor>data).withQuantity) {
      if (Array.isArray(data.value)) {
        if (data.value.some(item => (item.quantity ?? 0) <= 0)) {
          danger = true;
          data.currentErrorMessage = 'Missing Quantity';
        }
      } else if (data.value && (((<unknown>data.value) as { quantity: number }).quantity ?? 0) <= 0) {
        danger = true;
        data.currentErrorMessage = 'Missing Quantity';
      }
    }
    data.danger = danger;
  };

  generateConcatenationLookup() {
    const allLookups = [];

    const clonedMetadata = cloneDeep(this.metadata);
    for (let i = 0; i < clonedMetadata.length; i++) {
      for (let j = 0; j < clonedMetadata[i].length; j++) {
        if (clonedMetadata[i][j].concatenation != null) {
          this.hasConcatenation = true;
        }
        clonedMetadata[i][j]['parentIndex'] = i;
        clonedMetadata[i][j]['childIndex'] = j;
      }
    }

    if (this.hasConcatenation) {
      const combinedArr = [].concat(...clonedMetadata);

      combinedArr.forEach(item => {
        if (item.concatenation != null) {
          const parentLookup: ParentLookup = {
            propertyKey: item.propertyKey,
            parentIndex: item.parentIndex,
            childIndex: item.childIndex,
            format: item.concatenation.format,
            edited: false,
            foundIndicator: item.concatenation.format,
            concatenationProperties: item.concatenation.propertyKeys,
            concatenationPropertyNames: [],
            autoGenerated: false,
          };

          parentLookup.concatenationProperties.forEach(attribute => {
            const object = combinedArr.find(o => o.propertyKey === attribute.propertyKey);
            attribute.parentIndex = object.parentIndex;
            attribute.childIndex = object.childIndex;
            parentLookup.concatenationPropertyNames.push(attribute.propertyKey);
          });
          allLookups.push(parentLookup);
        }
      });
      this.concatenationLookup = allLookups;
    }
  }

  getFinalConcatenation(item) {
    let formatValue = cloneDeep(item.format);
    item.concatenationProperties.forEach(attribute => {
      if (attribute.innerPropertyKey != null && attribute.subKey == null) {
        formatValue = formatValue.replace(
          attribute.propertyKey,
          this.metadata[attribute.parentIndex][attribute.childIndex].value[attribute.innerPropertyKey],
        );
      } else if (attribute.subKey != null) {
        formatValue = formatValue.replace(
          attribute.propertyKey,
          this.metadata[attribute.parentIndex][attribute.childIndex].value[attribute.innerPropertyKey][attribute.subKey],
        );
      } else {
        formatValue = formatValue.replace(attribute.propertyKey, this.metadata[attribute.parentIndex][attribute.childIndex].value);
      }
    });
    if (this.metadata[item.parentIndex][item.childIndex].value === formatValue && !item.autoGenerated) item.autoGenerated = true;
    if (!this.metadata[item.parentIndex][item.childIndex].value || item.autoGenerated) {
      this.metadata[item.parentIndex][item.childIndex].value = formatValue;
      this.metadata[item.parentIndex][item.childIndex].editable = true;
      item.autoGenerated = true;
      this.formChange.emit(this.metadata[item.parentIndex][item.childIndex]);
    } else {
      item.edited = true;
      this.metadata[item.parentIndex][item.childIndex].editable = true;
      this.formChange.emit(this.metadata[item.parentIndex][item.childIndex]);
    }
  }

  checkConcatenation(subItem: unknown) {
    this.concatenationLookup.forEach(item => {
      if (item.concatenationPropertyNames.includes(subItem['propertyKey']) && !item.edited) {
        item.concatenationProperties.forEach(innerItem => {
          if (innerItem.propertyKey === subItem['propertyKey']) {
            item.foundIndicator = item.foundIndicator.replace(innerItem.propertyKey, 'Found');
            if (!item.concatenationPropertyNames.some(arrItem => item.foundIndicator.includes(arrItem))) {
              this.getFinalConcatenation(item);
            }
          }
        });
      } else if (item.propertyKey === subItem['propertyKey'] && !item.edited) {
        if (document.activeElement.id === item.propertyKey) {
          item.edited = true;
        }
      }
    });
    this.formChange.emit(subItem);
  }

  modelChange(subItem: unknown) {
    this.onChange();
    if (this.hasConcatenation) {
      this.checkConcatenation(subItem);
    } else {
      this.formChange.emit(subItem);
    }
  }

  buttonAction(subItem: CompiledFieldData, item: CompiledFieldData[][]) {
    subItem.action(item);
  }

  cancel() {
    if (!this.overrideCancel) {
      window.history.back();
    } else {
      this.cancelChange.emit(true);
    }
  }
}
