import { OVField, OVEntity, OVTable, OVSearchable, OVSearch } from '@ov-suite/ov-metadata';
import { Customer, Domain } from '@ov-suite/models-admin';
import moment from 'moment/moment';
import { OrderPriorityConstant } from '@ov-suite/helpers-shared';
import { AddressModel } from '@ov-suite/models-shared';
import { OrderItem } from './order-item.model';
import { OrderCommentModel } from './order-comment.model';
import { SalesRepModel } from './sales-rep.model';

@OVEntity({ name: 'Order' })
@OVTable<Order>([
  {
    key: 'id',
    title: 'Order ID',
    type: 'number',
  },
  {
    key: 'orderCode',
    title: 'Order Code',
    type: 'string',
  },
  {
    title: 'Customer Code',
    type: 'other',
    keys: ['customer', 'customer.customerCode'],
    action: order => order?.customer?.customerCode,
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'Customer Name',
    type: 'other',
    keys: ['customer.name'],
    action: order => order?.customer?.name,
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'Delivery Location',
    filterKey: 'address',
    type: 'other',
    keys: ['address'],
    hideColumnKey: 'address',
    action: order => order?.deliveryAddress?.getOneLiner() ?? 'No Address Set',
  },
  {
    key: 'orderDate',
    title: 'Order Date',
    type: 'date',
  },
  {
    key: 'fulfilmentDate',
    title: 'Shipping Date',
    type: 'date',
  },
  {
    key: 'completedDate',
    title: 'Completed Date',
    type: 'date',
    hideColumnKey: 'completedDate',
    disableFiltering: true,
    disableSorting: true,
  },
  {
    title: 'SLA',
    type: 'other',
    keys: [''],
    action: order => `${moment(order?.fulfilmentDate).diff(moment(order?.orderDate), 'days')} days`,
    disableFiltering: true,
    disableSorting: true,
  },
  {
    title: 'Priority',
    filterKey: 'priority',
    disableFiltering: true,
    keys: ['priority', 'orderItems'], // using orderItems for the popup
    type: 'other',
    action: order => `<span [class]="'badge '+ ${OrderPriorityConstant[order?.priority]?.color}">
            ${OrderPriorityConstant[order?.priority]?.text} </span>`,
  },
])
@OVSearch<Order>({ searchKeys: ['id', 'customer.name', 'orderDate'] })
export class Order {
  @OVField({ type: 'string' })
  id: number;

  // @OVField({ type: 'map' })
  // map: MapLocation;

  @OVField({ type: 'string' })
  specialInstructions?: string;

  // @OVField({ type: 'string' })
  // dueDate: string;

  @OVField({ type: 'string' })
  fulfilmentDate: string;

  @OVField({ type: 'string' })
  orderDate: string;

  @OVField({ type: 'string' })
  completedDate: string;

  @OVSearchable()
  @OVField({ type: 'string' })
  orderCode: string;

  @OVField({ type: 'number' })
  priority: number;

  @OVField({ type: () => Customer })
  customer: Customer;

  @OVField({
    type: () => [OrderItem],
    keys: ['id', 'quantity', 'productSku.id', 'productSku.sku', 'productSku.name', 'productSkuId'],
  })
  orderItems: OrderItem[];

  @OVField({
    type: () => [Domain],
    selectionType: 'multiple',
    title: 'Domains',
    required: true,
  })
  domains: Domain[];

  @OVField({ type: () => [OrderCommentModel] })
  comments: OrderCommentModel[];

  @OVField({ type: 'number' })
  salesRepId: number;

  @OVField({ type: () => SalesRepModel })
  salesRep: SalesRepModel;

  @OVField({ type: 'date-time' })
  createDate: string;

  @OVField({ type: () => AddressModel })
  deliveryAddress: AddressModel;
}
