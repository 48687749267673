export enum OrderEventType {
  orderCreated = 'orderCreated',
  orderSyncedFromSage = 'orderSyncedFromSage',
  orderApproved = 'orderApproved',
  orderUnapproved = 'orderUnapproved',
  orderUpdated = 'orderUpdated',
  orderAllocatedToLoad = 'orderAllocatedToLoad',
  orderUnAllocatedToLoad = 'orderUnAllocatedToLoad',
  orderReleased = 'orderReleased',
  orderPickingStarted = 'orderPickingStarted',
  orderPickingFinished = 'orderPickingFinished',
  orderLoadingStarted = 'orderLoadingStarted',
  orderLoadingFinished = 'orderLoadingFinished',
  orderShipped = 'orderShipped',
}
