/* eslint-disable @typescript-eslint/naming-convention */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Environment, setEnvironment } from '@ov-suite/helpers-shared';
import Amplify from '@aws-amplify/core';
import { AppModule } from './app/app.module';
import '@angular/compiler';

fetch('/assets/config.json', {
  method: 'GET',
})
  .then(response => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  })
  .then((data: Environment) => {
    console.log(data);
    localStorage.setItem('config', JSON.stringify(data));

    if (data.production) {
      enableProdMode();
    }
    setEnvironment(data);

    // Sentry.init({
    //   dsn: 'https://0b7b4582bf074070bce339393d86b0c5@sentry.linksuite.io/2',
    //   integrations: [
    //     // @ts-ignore
    //     new Integrations.BrowserTracing({
    //       tracingOrigins: ['localhost', environment.webUrl.admin, environment.webUrl.idm],
    //       routingInstrumentation: Sentry.routingInstrumentation,
    //     }),
    //   ],
    //
    //   // We recommend adjusting this value in production, or using tracesSampler
    //   // for finer control
    //   release: 'adminlink-web-1.2.3',
    //   environment: environment?.webUrl?.admin ? environment.webUrl.admin : 'dev',
    //   tracesSampleRate: 1.0,
    // });

    Amplify.configure({
      Auth: {
        identityPoolId: data.cognito.identityPoolId,
        region: data.cognito.region,
        userPoolId: data.cognito.userPoolId,
        userPoolWebClientId: data.cognito.clientId,
        cookieStorage: {
          domain: data.domain,
          secure: data.domain !== 'localhost',
          path: '/',
          expires: 365,
        },
      },
      aws_project_region: data.cognito.region,
      aws_cognito_identity_pool_id: data.cognito.identityPoolId,
      aws_cognito_region: data.cognito.region,
      aws_user_pools_id: data.cognito.userPoolId,
      aws_user_pools_web_client_id: data.cognito.clientId,
      oauth: data.cognito.oauth,
      aws_user_files_s3_bucket: data.cognito.bucketId,
      aws_user_files_s3_bucket_region: data.cognito.region,
    });

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  })
  .catch(error => {
    console.warn('Something went wrong.', error);
  });
