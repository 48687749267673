<div class="main-content" [ngStyle] = "{'height.px': screenHeight - 1}">
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Notifications List'"
      [formClass]="formClass"
      [overrideColumnData]="columns"
      [hasBulkUpload]="false"
      [excludeColumns]="4"
      [showTopBar]="true"
      [selectableRows]="false"
      [editableRows]="false"
      [hideAddButton]="true"
      [hasBulkExport]="false"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>

