import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Constructor, FieldMetadata, HasId } from '@ov-suite/ov-metadata';
import { ApiOptions } from '@ov-suite/ui';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BulkUploadXlService } from './bulk-upload-xl.service';
import moment from 'moment';
import { MapLocation } from '@ov-suite/models-helper';
import { getCreate } from '@ov-suite/graphql-helpers';
import { OvAutoService } from '@ov-suite/services';

interface UploadResponse {
  success: boolean;
  error: string;
  other: unknown;
}

interface BulkError {
  row: number;
  column?: number;
  error: string;
}

@Component({
  selector: 'ov-suite-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss'],
})
export class BulkUploadComponent<T extends HasId> implements OnInit {
  @Input() exportFunc = () => {
    this.exportAll('/api/bulk-upload/export', 'Export').then();
  };

  @Input() metadata: FieldMetadata;

  @Input() fileName: string;

  @ViewChild('content') content;

  @Input() set trigger(trigger: number) {
    if (trigger) this.open(this.content);
  }

  @Input() set callExport(trigger: number) {
    if (trigger) this.exportAll('/api/bulk-upload/export', 'Export').then();
  }

  @Input() set filterQuery(query: Record<string, (string | number)[]>) {
    if (query) this.queryFilterOn = query;
  }

  @Input() set searchQuery(query: Record<string, (string | number)[]>) {
    if (query) this.querySearchOn = query;
  }

  @Output() hasUploaded = new EventEmitter();

  queryFilterOn: Record<string, (string | number)[]>;

  querySearchOn: Record<string, (string | number)[]>;

  currentFiles: FileList;

  errors: BulkError[] = [];

  loading: boolean;

  uploaded: boolean;

  closeResult: string;

  constructor(
    private readonly modalService: NgbModal,
    @Inject('OPTIONS') private readonly options: ApiOptions,
    protected http: HttpClient,
    private ovAutoService: OvAutoService,
  ) {}

  ngOnInit() {}

  getDismissReason(reason: unknown): string {
    this.hasUploaded.emit(this.uploaded);
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    }
    if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    }
    return `with: ${reason}`;
  }

  open(content: unknown) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
  }

  async downloadTemplate() {
    const service = new BulkUploadXlService();

    await service.create(this.metadata);

    // if (!this.metadata) {
    //   return;
    // }
    // await this.exportAll('/api/bulk-upload/template', 'Template');
  }

  async exportAll(apiUrl: string, templateName: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    await this.http
      .post<Blob>(
        this.options.apiUrl + apiUrl,
        { className: this.metadata.name, query: this.queryFilterOn, search: this.querySearchOn },
        { headers, responseType: 'blob' as 'json' },
      )
      .subscribe(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = url;
        a.download = `${this.metadata.name + templateName}.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
      }, console.error);
  }

  onFileChange(event: Event) {
    const target: DataTransfer = event.target as unknown as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot upload multiple files.');
    }
    this.currentFiles = (event?.target as HTMLInputElement)?.files;
  }

  onFileUploadClick() {
    document.getElementById('xlxs-file-reader').click();
  }

  async onFileSave() {
    const service = new BulkUploadXlService();
    // @ts-ignore // Breaks on build without this ignore
    service.getDataFromFile(this.currentFiles.item(0)).then(response => {
      const data = this.parseResponse(response).map(l => getCreate(l));

      const hasErrors = this.evaluateErrors(data);

      if (!hasErrors) {
        this.ovAutoService
          .bulkInsert({
            entity: this.metadata.entity as Constructor<HasId>,
            items: data,
          })
          .then(() => {
            this.back();
          });
      }

      return;
    });
    // const form = new FormData();
    // form.append('file', this.currentFiles.item(0));
    // form.append('data', JSON.stringify({ className: this.metadata.name }));
    // this.loading = true;
    // this.uploaded = false;
    //
    // this.http.post(`${this.options.apiUrl}/api/bulk-upload/upload`, form).subscribe(
    //   (res: UploadResponse) => {
    //     if (res.success) {
    //       this.uploaded = true;
    //     } else {
    //       this.errors = JSON.parse(res.error);
    //     }
    //     this.loading = false;
    //   },
    //   err => {
    //     if (err.error && err.error.error) {
    //       this.errors = JSON.parse(err.error.error);
    //     }
    //     this.loading = false;
    //   },
    // );
  }

  evaluateErrors(inputData: unknown[]): boolean {
    let output = false;
    const required = this.metadata.fields.filter(f => f.required);

    inputData.forEach((i, index) => {
      required.forEach(field => {
        const key = field.propertyKey;
        const value = i[key];
        if (!value) {
          this.errors.push({
            row: index + 3,
            error: `Missing Required Field: ${field.title}`,
          });
          output = true;
        }
      });
    });

    return output;
  }

  parseResponse<T extends HasId>(inputData: unknown[]): T[] {
    return inputData.map(i => {
      const output = new this.metadata.entity();
      this.metadata.fields.forEach(field => {
        const key = field.propertyKey;
        const value = i[key];
        const getNum = (input: unknown): number => (Number.isNaN(Number(input)) ? null : Number(input));
        switch (field.type) {
          case 'image':
          case 'password':
          case 'string':
            output[key] = value;
            break;
          case 'map':
            if (value) {
              output[key] = <MapLocation>{
                address: value,
                latitude: getNum(i[`${key}.latitude`]),
                longitude: getNum(i[`${key}.longitude`]),
              };
            } else {
              output[key] = null;
            }
            break;
          case 'domain-selector':
            // todo: Attach Domain;
            break;
          case 'date-time':
          case 'time':
          case 'date':
            output[key] = moment(value).isValid() ? moment(value).toDate() : null;
            break;
          case 'numbers':
          case 'number':
            output[key] = getNum(value);
            break;
          case 'boolean':
            switch (value.toLowerCase()) {
              case 'yes':
                output[key] = true;
                break;
              case 'no':
                output[key] = false;
                break;
              default:
            }
            break;
          case 'json':
          case 'code-preview':
          case 'button':
          case 'blank':
          case 'recurrence':
          case 'permission':
          case 'title':
            break; // Ignore
          default:
        }
      });

      return output as T;
    });
  }

  back() {
    this.hasUploaded.emit(this.uploaded);
    this.modalService.dismissAll();
  }
}
