<div class="hierarchy-table shadow-sm">
  <ng-container
    *ngIf="hasFilters() || data.length > 0 || !emptyComponent || searchFilter || loading; else emptyComponent">
  <div class="hierarchy-table-card card slide-in-right">
    <div class="row d-flex flex-row justify-content-between align-items-center m-2 table-header-component">
      <div class="ht-header">
        <span class="table-title" *ngIf="!titleReplace; else TitleReplace">{{ title }}</span>
        <ng-template #TitleReplace>
          <ng-container *ngComponentOutlet="titleReplace"></ng-container>
        </ng-template>
      </div>
      <div class="hierarchy-input-container">
        <div *ngIf="!!searchMetadata" class="hierarchy-advanced-search">
          <ov-suite-advanced-search
          [dropdownData]="dropdownData"
          (searchChange)="onSearchChange($event)"
          (clearSearch)="onClearSearch($event)"
          (filterChange)="filterChange($event)"
          [currentFilter]="currentFilterChange"
          [searchDropdownPosition]="searchDropdownPosition"
          [metadata]="metadata"
          [keyStore]="metadata?.name">
          </ov-suite-advanced-search>
        </div>
        <div class="hierarchy-input">
          <button class="btn btn-dark btn-table-customize" (click)="customize()">
            <span *ngIf="!customizing" class="float-right">
               <ov-suite-icon class="text-dark" [name]="'settings'"> </ov-suite-icon>
            </span>
            <span *ngIf="customizing" class="float-right">
              <i aria-hidden="true" class="fa fa-check text-dark"></i>
            </span>
          </button>
        </div>
        <div *ngIf="hasBulkExport" class="hierarchy-input">
          <button (click)="uploadComponent.exportFunc()" class="btn import-button"> Export </button>
        </div>
        <div
          *ngIf="hasCreatePermission && (hasBulkUpload || hasBulkExport)"
          class="hierarchy-input"
          [hidden]="hasBulkExport && !hasBulkUpload">
          <ov-suite-bulk-upload
            class="ov-suite-bulk-upload"
            *ngIf="hasCreatePermission" [metadata]="metadata"
            [filterQuery]="filterMap"
            [searchQuery]="searchMap"
            (hasUploaded)="uploaded($event)"
            #uploadComponent
          ></ov-suite-bulk-upload>
        </div>
        <div *ngIf="hasCreatePermission && !hideAddButton" class="hierarchy-input">
          <button
            *ngIf="hasCreatePermission"
            class="btn btn-dark btn-block btn-round"
            (click)="add()"
          >
            <p class="m-0 p-0">Add</p>
            <span class="float-right mr-1"
              ><i aria-hidden="true" class="fa fa-plus ml-1"></i
            ></span>
          </button>
        </div>
        <div *ngIf="bulkActionComponent && hasEditPermission" class="hierarchy-bulk-action-input">
          <div>
            <div ngbDropdown display="dynamic" placement="left-top" class="d-inline-block">
              <button class="btn btn-outline-primary bulk-action-btn" id="bulkActionDropdown" ngbDropdownToggle>Bulk Actions &nbsp; &nbsp;</button>
              <div ngbDropdownMenu aria-labelledby="bulkActionDropdown">
                <ng-container *ngTemplateOutlet="bulkActionComponent"> </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-body" [ngClass]="{ 'show-scrollbar': true }"
    >
      <ov-suite-basic-table
        [striped]="true"
        [selectableRows]="selectableRows"
        [clickableRows]="clickableRows"
        [showFiller]="showFiller"
        [data]="data"
        [loading]="loading"
        [columnData]="overrideColumnData || metadata?.table"
        [totalCount]="totalCount"
        [pageSize]="pageSize"
        [currentPage]="page"
        (changePage)="changePage($event)"
        (itemSelect)="onItemSelected($event)"
        [select]="select"
        [back]="back"
        (clickRow)="onRowClick($event)"
        [hideColumnKeys]="hideColumnKeys"
        [keyStore]="metadata?.name"
        (orderChange)="orderChange($event)"
        [columnHider]="customizing"
        (itemEdited)="onItemEdit($event)"
        (rowItemClick)="onRowItemClick($event)"
        (rowItemButtonClick)="onRowItemButtonClick($event)"
        [dropdownData]="dropdownData"
        (changePageSize)="pageSizeInput($event)"
      ></ov-suite-basic-table>
    </div>
  </div>
  </ng-container>
</div>
