import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';
import { EquipmentType } from './equipment-type.model';
import { Domain } from './domain.model';

@OVEntity('EquipmentStatus', 'adminlink')
export class EquipmentStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Equipment', 'adminlink')
@OVForm([['#Equipment Details'], ['equipmentID', 'name'], ['description'], ['type', 'domains'], ['barcodeData', ''], ['qrPreview']])
@OVSidebar([['imageURL'], ['status'], ['id']])
@OVTable<Equipment>([
  {
    key: 'equipmentID',
    title: 'Category ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Equipment Type Name',
    type: 'string',
  },
])
@OVSearch<Equipment>({ searchKeys: ['equipmentID', 'name', 'barcodeData'] })
export class Equipment {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Equipment Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Equipment ID',
    tooltip: 'Used as a unique key for identification throughout the site.',
    placeholder: 'Required',
    required: true,
  })
  equipmentID: string;

  @OVSearchable()
  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageURL?: string;

  @OVField({
    type: () => EquipmentType,
    tooltip: 'Add a single equipment type.',
    selectionType: 'single',
    title: 'Type',
    required: true,
    flat: true,
  })
  type: EquipmentType[];

  @OVField({ type: 'string', title: 'Barcode Type' })
  barcodeType: string;

  @OVField({ type: 'string', title: 'Barcode Value' })
  barcodeData: string;

  @OVField({
    type: () => EquipmentStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: EquipmentStatus;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: false,
    generated: true,
    readonly: true,
  })
  domains: Domain[];

  @OVField({
    type: 'code-preview',
    unnecessary: true,
    codeKey: 'barcodeData',
  })
  qrPreview: void;
}
