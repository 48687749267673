import { OVEntity, OVField, OVForm, OVSearchable, OVSidebar, OVTable } from '@ov-suite/ov-metadata';
import { UserType } from '@ov-suite/models-idm';

@OVEntity('MobileSodStatus', 'shared')
export class MobileSodStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('MobileSod', 'shared')
@OVForm([['name', 'icon'], ['processDefinitionKey', ''], ['userTypes']])
@OVSidebar([['status']])
@OVTable([
  {
    type: 'string',
    key: 'name',
    title: 'Name',
  },
  {
    type: 'string',
    key: 'processDefinitionKey',
    title: 'Process Definition Key',
  },
])
export class MobileSod {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Start of Day',
    required: false,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Process Definition Key',
    placeholder: 'Required',
    required: true,
  })
  processDefinitionKey: string;

  @OVField({
    type: () => [UserType],
    keys: ['id', 'name'],
    title: 'User Type',
    tooltip: 'Add multiple user types. This determines a users permission throughout the site.',
    selectionType: 'multiple',
    flat: true,
    required: true,
  })
  userTypes: UserType[];

  @OVField({
    type: 'string',
    title: 'Icon',
  })
  icon: string;

  @OVField({
    type: () => MobileSodStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: MobileSodStatus;
}
