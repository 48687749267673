import { Component } from '@angular/core';
import { User } from '@ov-suite/models-idm';

@Component({
  selector: 'ov-suite-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent {
  // Class - Required
  formClass = User;
}
