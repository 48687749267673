import { OVForm, OVEntity, OVField, OVTable, OVSidebar, OVSearch } from '@ov-suite/ov-metadata';

@OVEntity('Transporter', 'shared')
@OVForm([['name', 'transporterCode'], ['companyRegistration']])
@OVSidebar([
  // ['image'],
  ['id'],
])
@OVTable<Transporter>([
  {
    key: 'id',
    title: 'Transporter ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    key: 'transporterCode',
    title: 'Transporter Code',
    type: 'string',
  },
  {
    key: 'companyRegistration',
    title: 'Company Registration',
    type: 'string',
  },
])
@OVSearch<Transporter>({ searchKeys: ['name', 'transporterCode', 'companyRegistration'] })
export class Transporter {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Transporter Name',
    required: true,
    placeholder: 'Required',
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'Transporter Code',
    required: true,
    placeholder: 'Required',
  })
  transporterCode: string;

  @OVField({
    type: 'string',
    title: 'Company Registration',
    required: true,
    placeholder: 'Required',
  })
  companyRegistration: string;
}
