<div class="top-filters-container">
  <div *ngFor="let filter of filters">
    <div ngbDropdown class="d-inline-block" [ngSwitch]="filter.type">
      <button class="btn btn-outline-primary filter-button" [id]="filter + filter.key" ngbDropdownToggle>
        {{ selectedLabels[filter.key] || filter.placeholder }}
      </button>
      <div ngbDropdownMenu>
        <ng-container *ngSwitchCase="'lookup'">
          <button
            ngbDropdownItem
            *ngFor="let option of data[filter.key]"
            (click)="selectFilter(filter, option.id, option[filter.labelKey])"
          >
            {{ option[filter.labelKey] }}
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="'static'">
          <button ngbDropdownItem *ngFor="let option of filter.data" (click)="selectFilter(filter, option.key, option.label)">
            {{ option.label }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</div>
