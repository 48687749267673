import gql from 'graphql-tag';
import { Task } from '@ov-suite/helpers-shared';

export function getTaskQuery(keys: (keyof Task)[]) {
  return gql(`query GetTask($taskId: String!) {
    getTask(taskId: $taskId) {
      ${keys.join(`\n`)}
      variables {
        name
        value
      }
     }
   }`);
}

export function fetchTasksQuery() {
  return gql(`
    query FetchTasks($params: FetchTasksInput!) {
      fetchTasks(params: $params) {
        data {
          id
          form
          processInstanceId
          formKey
          category
          name
          priority
          taskDefinitionKey
          variables {
            name
            value
          }
        }
        total
        start
        sort
        order
        size
      }
    }
  `);
}

export function listTasksQuery(keys: (keyof Task)[]) {
  return gql(`query ListTasks($query: ListTasksInput!) {
    listTasks(query: $query) {
      data {
        ${keys.join(`\n`)}
        variables {
          name
          value
        }
      }
      total
      start
      sort
      order
      size
     }
   }`);
}

export function getPalletStateQuery() {
  return gql(`
    query getPalletStateQuery($code: String!) {
      getPalletStateQuery(code: $code) {
        id
        name
        description
        data
      }
    }
  `);
}

export function scanForTaskQuery() {
  return gql(`
    query ScanForTaskV2($params: ScanForTaskInput!) {
      scanForTaskV2(params: $params) {
        id
        name
        assignee
        description
        processInstanceId
        variables {
          name
          value
        }
      }
    }
  `);
}

export function getTaskListsQuery() {
  return gql(`
    query GetTaskLists($params: TaskListInput!) {
      tasklists: getTaskListsV2(params: $params) {
        assigned {
          id
          name
          assignee
          description
          createTime
          processInstanceId
          category
          variables {
            name
            value
          }
        }
        candidate {
          id
          name
          assignee
          description
          createTime
          processInstanceId
          category
          variables {
            name
            value
          }
        }
        searchTerm
      }
    }
  `);
}

export function completeTaskMutation() {
  return gql(`
    mutation CompleteTask($params: CompleteTaskInput!) {
      completeTask(params: $params) {
        ok
        tasks { id assignee, id, processInstanceId, form, formKey, category, name, priority, variables { name value } }
      }
    }
  `);
}

export function taskActionMutation(keys: ('ok' | 'tasks')[] = ['ok']) {
  return gql(`mutation TaskAction($action:TaskActionsInput!, $taskId:String!) {
    taskAction(action: $action, taskId: $taskId) {
      ${keys.includes('ok') ? 'ok' : ''}
      ${keys.includes('tasks') ? 'tasks { id assignee, id, form, formKey, category, name, priority, variables { name value } }' : ''}
     }
   }`);
}

export function claimTaskMutation(keys: ('ok' | 'tasks')[] = ['ok']) {
  return gql(`mutation ClaimTask($taskId:String!) {
    claimTask(taskId: $taskId) {
      ${keys.includes('ok') ? 'ok' : ''}
      ${keys.includes('tasks') ? 'tasks { id assignee, id, form, formKey, category, name, priority, variables { name value } }' : ''}
     }
   }`);
}

export function getFormByKey() {
  return gql(`
    query getFormByKey($key: String!) {
      getFormByKey(key: $key) {
          id
          json
      }
    }
  `);
}
