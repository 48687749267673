import { getTaggedClass, OVEntity, OVField, OVSearchable, OVTable } from '@ov-suite/ov-metadata';
import { Domain, VehicleClass, VehicleStatus } from '@ov-suite/models-admin';
import { VehicleException } from './vehicle-exception.model';
import { VehicleResource } from './vehicle-resource.model';
import { PermissionType } from '@ov-suite/helpers-shared';

@OVEntity('Vehicle', 'warehouselink')
@OVTable<VehicleOverride>([
  {
    type: 'deep-string',
    title: 'Vehicle Reg.',
    filterKey: 'registration',
    key: 'registration',
  },
  {
    title: 'Vehicle Class',
    type: 'deep-string',
    filterKey: 'vehicle.class.name',
    key: 'class.name',
  },
  {
    title: 'Dimension',
    type: 'other',
    disableFiltering: true,
    keys: ['class.height', 'class.width', 'class.length'],
    action: item => String(item.class?.height * item.class?.width * item.class?.length),
  },
  {
    title: 'Weight',
    type: 'other',
    disableFiltering: true,
    keys: ['class.weightLoadAllowed'],
    action: item => String(item.class?.weightLoadAllowed),
  },
  {
    title: 'Weight Red Percentage',
    type: 'other',
    disableFiltering: true,
    keys: ['class.weightRedPercentage'],
    action: item => String(item.class?.weightRedPercentage),
  },
  {
    title: 'Weight Orange Percentage',
    type: 'deep-string',
    disableFiltering: true,
    key: 'class.weightOrangePercentage',
  },
  {
    title: 'Start Time',
    editable: true,
    type: 'time',
    key: 'resource.startTime',
    permissionId: 102,
    permissionType: PermissionType.update,
  },
  {
    title: 'End Time',
    editable: true,
    type: 'time',
    key: 'resource.endTime',
    permissionId: 102,
    permissionType: PermissionType.update,
  },
  {
    title: 'Start Location',
    type: 'dropdown',
    key: 'resource.startLocation',
    filterKey: 'resource.startLocation.id',
    editable: true,
    displayKeys: ['name'],
    permissionId: 102,
    permissionType: PermissionType.update,
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'End Location',
    type: 'dropdown',
    key: 'resource.endLocation',
    filterKey: 'resource.endLocation.id',
    editable: true,
    displayKeys: ['name'],
    permissionId: 102,
    permissionType: PermissionType.update,
    disableSorting: true,
    disableFiltering: true,
  },

  {
    title: 'Exception',
    keys: ['exceptions'],
    disableFiltering: true,
    disableSorting: true,
    type: 'buttons',
    buttons: [
      {
        hide: item => !item?.exceptions?.length,
        actionType: 'modal',
        icon: 'fa fa-flag-o text-danger',
        classes: 'btn-link',
        action: (item, { ngbModal }) => {
          const modalRef = ngbModal.open(getTaggedClass('get-vehicle-exception-view-popup', VehicleException), { size: 'xl' });
          modalRef.componentInstance.name = 'View Vehicle Exceptions';
          modalRef.componentInstance.vehicle = item;
        },
      },
    ],
  },
])
export class  VehicleOverride {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Vehicle Code',
    tooltip: 'Code',
    placeholder: 'Required',
    required: true,
  })
  code: string;

  @OVField({
    type: 'string',
    title: 'Vehicle Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Registration',
    placeholder: 'Required',
    required: true,
  })
  registration: string;

  @OVField({
    type: () => VehicleClass,
    title: 'Vehicle Class',
    placeholder: 'Required',
    required: false,
  })
  class: VehicleClass;

  @OVField({
    type: () => VehicleStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: VehicleStatus;

  @OVField({
    type: () => VehicleResource,
    title: 'Vehicle Resource',
    keys: ['id', 'startTime', 'startLocation.id', 'endLocation.id', 'endTime'],
    placeholder: 'Required',
    selectionType: 'single',
    required: false,
  })
  resource: VehicleResource;

  @OVField({
    type: () => [VehicleException],
    title: 'Exception',
    keys: ['id'],
    placeholder: 'Required',
    selectionType: 'single',
    required: true,
  })
  exceptions: VehicleException[];

  @OVField({
    type: () => [Domain],
    selectionType: 'multiple',
    keys: ['id', 'name', 'path'],
    title: 'Domains',
    required: true,
  })
  domains: Domain[];
}
