import { OVEntity, OVField, OVSearchable, OVTable } from '@ov-suite/ov-metadata';

@OVEntity('ExceptionStatus', 'shared')
export class ExceptionCommonStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('ExceptionCategory', 'shared')
export class ExceptionCategory {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;
}

@OVEntity('ExceptionType', 'shared')
export class ExceptionType {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  key: string;

  @OVField({ type: 'string' })
  severity: string;

  @OVField({
    type: () => ExceptionCategory,
    keys: ['name', 'id'],
    dropdown: false,
    selectionType: 'single',
    title: 'Category',
  })
  category: ExceptionCategory;
}

@OVEntity('Exception', 'shared')
@OVTable<ExceptionCommon>([
  {
    type: 'string',
    key: 'detail',
    title: 'Exception Name',
  },
  {
    type: 'other',
    title: 'Users',
    action: i => i.involvedUsers.join(', '),
    keys: ['involvedUsers'],
  },
  // {
  //   type: 'deep-string',
  //   key: 'type.category.name',
  //   title: 'Category',
  // },
  // {
  //   type: 'string',
  //   key: 'severity',
  //   title: 'Severity',
  // },
  {
    type: 'date',
    key: 'createdDate',
    title: 'Time',
    format: 'YYYY-MM-DD HH:mm:ss',
  },
])
export class ExceptionCommon {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Name',
    required: false,
  })
  detail: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Source',
    placeholder: 'Source',
    required: false,
  })
  source: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Severity',
    placeholder: 'Severity',
    required: false,
  })
  severity: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Reporter',
    placeholder: 'Reporter',
    required: false,
  })
  reporter: string;

  @OVField({
    type: 'string',
    title: 'Created Date',
    placeholder: 'CreatedDate',
    required: false,
  })
  createdDate: string;

  @OVField({
    type: 'string',
    title: 'Users',
    tooltip: 'Add multiple users',
  })
  involvedUsers: string[];

  @OVField({
    type: () => ExceptionType,
    keys: ['id', 'category.name'],
    selectionType: 'single',
    dropdown: false,
    title: 'Category',
  })
  type: ExceptionType;
}
