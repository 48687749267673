import Auth from '@aws-amplify/auth';
import { CRUD, getCrud } from '@ov-suite/helpers-shared';

type PermissionColumn = 1 | 2 | 4 | 8;
export enum PermissionAction {
  create = 8,
  read = 4,
  update = 2,
  delete = 1,
}

export async function getWebCrud(permissionId: number): Promise<CRUD> {
  return new Promise(resolve => {
    Auth.currentSession().then(session => {
      const permissionsRaw: string = session.getIdToken().payload.lambdaPermissionIds;
      if (permissionsRaw) {
        const permissions: { id: number; value: number }[] = JSON.parse(permissionsRaw);
        resolve(getCrud(permissions.find(p => p.id === permissionId)?.value) ?? null);
      } else {
        resolve(null);
      }
    });
  });
}

export async function getPermissionIds(): Promise<number[]> {
  return new Promise(resolve => {
    Auth.currentSession().then(session => {
      const permissionsRaw: string = session.getIdToken().payload.lambdaPermissionIds;
      if (permissionsRaw) {
        const permissions: { id: number; value: number }[] = JSON.parse(permissionsRaw);
        resolve(permissions.filter(p => verifyPermission(p.value, 4)).map(p => p.id));
      } else {
        resolve([]);
      }
    });
  });
}

export const verifyPermission = (value: number, column: PermissionColumn): boolean => {
  // eslint-disable-next-line no-bitwise
  return (value | column) === value;
};

export const hasAppPermission = async (appId: number): Promise<boolean> => {
  return Auth.currentSession()
    .then(session => {
      console.log({ session });
      const currentUserTokenInfo = session.getIdToken().decodePayload();
      const apps = JSON.parse(currentUserTokenInfo['lambdaAppIds']);
      const hasAppId = apps.find(id => id === appId);

      if (hasAppId) {
        return Promise.resolve(hasAppId === appId);
      }
      return Promise.resolve(false);
    })
    .catch(error => {
      // if there is no user default to true, so the iframe handler can take over
      return Promise.resolve(true);
    });
};

export const hasPermissionByFeature = async (feature: number): Promise<number> => {
  const session = await Auth.currentSession();
  const currentUserTokenInfo = session.getIdToken().decodePayload();
  const permissions = JSON.parse(currentUserTokenInfo['lambdaPermissionIds']);
  const hasPerm = permissions.find(_feature => _feature.id === feature);
  return Promise.resolve(hasPerm.value);
};

export const hasFeaturePermission = async (feature: number, action: PermissionAction): Promise<boolean> => {
  const session = await Auth.currentSession();
  if (!session) {
    return Promise.resolve(false);
  }
  const currentUserTokenInfo = session.getIdToken().decodePayload();
  const permissions = JSON.parse(currentUserTokenInfo['lambdaPermissionIds']);
  const hasPerm = permissions.find(_feature => _feature.id === feature);

  if (hasPerm) {
    if (verifyPermission(hasPerm.value, action)) {
      return Promise.resolve(true);
    }
  }
  return Promise.resolve(false);
};

export const getTransporterIds = async (): Promise<number[]> => {
  const session = await Auth.currentSession();
  const currentUserTokenInfo = session.getIdToken().decodePayload();
  const transporters = 'lambdaTransporterIds';
  return JSON.parse(currentUserTokenInfo[transporters]);
};
