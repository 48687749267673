import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { FeatureType } from '@ov-suite/helpers-shared';
import { FeatureStatus } from './feature-status.model';

interface FeatureRequirement {
  id: number;
  value: number;
  lock: number;
}

@OVEntity('Feature', 'idmlink')
export class Feature {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;
  // @Field({type: App, unnecessary: true})
  // app: App;

  @OVField({ type: 'number' })
  defaultPermission: number;

  @OVField({ type: 'json' })
  requirements?: FeatureRequirement[];

  @OVField({ type: () => FeatureStatus })
  status: FeatureStatus;

  @OVField({
    type: 'string',
    title: 'Action',
    tooltip: 'Action',
    placeholder: '',
    required: true,
  })
  featureType: FeatureType;
}
