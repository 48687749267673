import { FieldValidator } from '@ov-suite/helpers-angular';
import { User } from '@ov-suite/models-idm';
import { OvAutoService } from '@ov-suite/services';
import gql from 'graphql-tag';
import { Injectable } from '@angular/core';

@Injectable()
export class EmailValidator implements FieldValidator<User> {
  errorMessage = 'Email Address already In Use';

  constructor(private readonly ovAutoService: OvAutoService) {}

  async validate(user: User): Promise<Boolean> {
    return this.ovAutoService.apollo
      .use('adminlink')
      .query<boolean>({
        query: gql(`query checkUserExists($email: String!) {
          checkUserExists(email: $email)
        }`),
        variables: {
          email: user.email,
        },
      })
      .toPromise()
      .then(res => !res.data['checkUserExists']);
  }

  async required(user: User): Promise<Boolean> {
    return user.cognitoUser;
  }
}
