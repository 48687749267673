import { OVForm, OVEntity, OVField } from '@ov-suite/ov-metadata';
import { environment } from '@ov-suite/helpers-shared';

@OVEntity('Profile', 'ceramic-portal')
@OVForm([
  ['email', 'phoneNumber'],
  ['name', 'familyName'],
  ['password', 'newPassword', 'confirmPassword'],
])
export class Profile {
  id: number;

  @OVField({ type: 'string', title: 'Mobile', required: true })
  phoneNumber: string;

  @OVField({ type: 'string', title: 'First Name', required: true })
  name: string;

  @OVField({ type: 'string', title: 'Last Name', required: true })
  familyName: string;

  @OVField({
    tooltip: 'Enter your current password',
    type: 'password',
    title: 'Current Password',
    required: false,
  })
  password: string;

  @OVField({
    type: 'password',
    title: 'New Password',
    required: false,
    tooltip: environment.cognito.passwordPolicyText,
  })
  newPassword: string;

  @OVField({
    tooltip: 'Has to match new password.',
    type: 'password',
    title: 'Confirm Password',
    required: false,
    validatorInjectionKey: 'PASSWORD_VALIDATOR',
  })
  confirmPassword: string;

  @OVField({
    type: 'string',
    tooltip: 'Requires a valid email address.',
    title: 'Email Address',
    // validator: validateEmail,
    required: true,
  })
  email: string;
}
