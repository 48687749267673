import { Component, ElementRef, Input, Inject, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Icons } from './icons.provider';
import { uppercamelcase } from './utils';

@Component({
  selector: 'ov-suite-icon',
  templateUrl: './ovsuite-icons.component.html',
  styleUrls: ['./ovsuite-icons.component.scss'],
})
export class OvsuiteIconsComponent implements OnChanges {
  @Input() name!: string;

  constructor(
    @Inject(ElementRef) private readonly elem: ElementRef,
    @Inject(ChangeDetectorRef) private readonly changeDetector: ChangeDetectorRef,
    @Inject(Icons) private readonly icons: Icons,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    // icons are provided as an array of objects because of "multi: true"
    const icons = Object.assign({}, ...(this.icons as never as object[]));
    const svg = icons[uppercamelcase(changes.name.currentValue)] || '';

    if (!svg) {
      console.warn(`Icon not found: ${changes.name.currentValue}`);
    }

    this.elem.nativeElement.innerHTML = svg;
    this.changeDetector.markForCheck();
  }
}
