import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminLayoutModule, GraphQLModule, UiModule } from '@ov-suite/ui';
import { JwtInterceptor } from '@ov-suite/authguard-angular';
import { environment } from '@ov-suite/helpers-shared';
import { DomainServiceInitializer, SharedModule } from '@ov-suite/helpers-angular';
import { FooterModule } from './shared/footer/footer.module';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppComponent } from './app.component';
import { angularRoutes, sidebarRoutes } from './app.routing';
import { UserService } from './services/user/user.service';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [AppComponent, AuthLayoutComponent],
  imports: [
    AdminLayoutModule.forRoot({
      projectName: 'Account Link',
      sidebarRoutes,
      angularRoutes,
    }),
    UiModule.forRoot({
      apiUrl: environment.apiUrl,
      imageUploadUrl: environment.other.awsFileServiceApi,
    }),
    FooterModule,
    SharedModule,
    GraphQLModule,
  ],
  providers: [
    UserService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: 'DEFAULT_API',
      useValue: 'idmlink',
    },
    DomainServiceInitializer,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
