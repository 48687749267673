import { OVField, OVEntity, OVTable, OVSearchable, OVSearch, getTaggedClass } from '@ov-suite/ov-metadata';
import { MapLocation } from '@ov-suite/models-helper';
import { Customer, Domain } from '@ov-suite/models-admin';
import moment from 'moment/moment';
import { OrderPriorityConstant } from '@ov-suite/helpers-shared';
import { OrderItem } from './order-item.model';

@OVEntity({ name: 'Order' })
@OVTable<ReturnOverride>([
  {
    keys: ['id'],
    title: 'Order ID',
    type: 'other',
    action: order => order.id.toString().padStart(4, '0'),
    rowItemAction: (item, { ngbModal, apiService }) => {
      const modalRef = ngbModal.open(getTaggedClass('order-details-modal', ReturnOverride), { size: 'xl' });
      modalRef.componentInstance.order = item;
    },
  },
  {
    key: 'orderDate',
    title: 'Order Date',
    type: 'date',
  },
  {
    key: 'orderCode',
    title: 'Order Code',
    hideColumnKey: 'orderCode',
    type: 'string',
    disableFiltering: true,
  },
  {
    title: 'Customer ID',
    type: 'other',
    keys: ['customer', 'customer.customerCode'],
    action: order => order?.customer?.customerCode,
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'Customer Name',
    type: 'other',
    keys: ['customer.name'],
    action: order => order?.customer?.name,
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'Priority',
    filterKey: 'priority',
    disableFiltering: true,
    keys: ['priority', 'orderItems'], // using orderItems for the popup
    type: 'other',
    action: order => `<span class="badge ${OrderPriorityConstant[order?.priority]?.color}">
            ${OrderPriorityConstant[order?.priority]?.text} </span>`,
  },
  {
    title: 'SLA',
    type: 'other',
    keys: [''],
    action: order => `${moment(order?.fulfilmentDate).diff(moment(order?.orderDate), 'days')} days`,
    disableFiltering: true,
    disableSorting: true,
  },
  {
    title: 'Delivery Location',
    filterKey: 'address',
    disableFiltering: true,
    type: 'other',
    keys: ['address'],
    hideColumnKey: 'address',
    action: order => order?.address,
  },
  {
    key: 'fulfilmentDate',
    title: 'Shipping Date',
    type: 'date',
    disableFiltering: true,
  },
  {
    key: 'completedDate',
    title: 'Completed Date',
    type: 'date',
    hideColumnKey: 'completedDate',
    disableFiltering: true,
    disableSorting: true,
  },
  {
    title: 'Actions',
    type: 'buttons',
    hideColumnKey: 'actions',
    disableSorting: true,
    disableFiltering: true,
    keys: [],
    buttons: [
      {
        classes: 'btn btn-dark btn-sm mx-1',
        icon: 'fa fa-pencil',
        action: async (item, apiService) => {
          console.log({ apiService, item });
        },
      },
      {
        classes: 'btn btn-dark btn-sm mx-1',
        icon: 'fa fa-user text-white',
        action: async (item, apiService) => {
          console.log({ apiService, item });
        },
      },
      {
        classes: 'btn btn-dark btn-sm mx-1',
        icon: 'fa fa-comment text-white',
        action: async (item, apiService) => {
          console.log({ apiService, item });
        },
      },
      {
        classes: 'btn btn-dark btn-sm mx-1',
        icon: 'fa fa-check text-white',
        action: async (item, apiService) => {
          console.log({ apiService, item });
        },
      },
      {
        classes: 'btn btn-dark btn-sm mx-1',
        icon: 'fa fa-file text-white',
        action: (item, { ngbModal }) => {
          console.log({ ngbModal, item });
        },
      },
    ],
  },
])
@OVSearch<ReturnOverride>({ searchKeys: ['id', 'customer.name', 'orderDate'] })
export class ReturnOverride {
  @OVField({ type: 'string' })
  id: number;

  // @OVField({ type: 'map' })
  // map: MapLocation;

  @OVField({ type: 'string' })
  specialInstructions?: string;

  // @OVField({ type: 'string' })
  // dueDate: string;

  @OVField({ type: 'string' })
  fulfilmentDate: string;

  @OVField({ type: 'string' })
  orderDate: string;

  @OVField({ type: 'string' })
  completedDate: string;

  @OVSearchable()
  @OVField({ type: 'string' })
  orderCode: string;

  @OVField({ type: 'string' })
  address: string;

  @OVField({ type: 'number' })
  priority: number;

  @OVField({ type: () => Customer })
  customer: Customer;

  @OVField({
    type: () => [OrderItem],
    keys: ['id', 'quantity', 'productSku'],
  })
  orderItems: OrderItem[];

  @OVField({
    type: () => [Domain],
    selectionType: 'multiple',
    title: 'Domains',
    required: true,
  })
  domains: Domain[];
}
