import { Injectable } from '@angular/core';
import { Order } from '@ov-suite/models-order';

@Injectable({
  providedIn: 'root',
})
export class NgOutletModalService {
  public data: Order;
  constructor() {}
}
