import { forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export abstract class AbstractValueAccessor<T> implements ControlValueAccessor {
  val: T;

  get value(): T {
    return this.val as T;
  }

  set value(v: T) {
    if (v !== this.val) {
      this.val = v;
      this.onChange(v);
    }
  }

  writeValue(value: T) {
    this.val = value;
    this.onChange(value);
  }

  onChange = _ => {};

  onTouched = () => {};

  registerOnChange(fn: (_: T) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}

export function MakeProvider<T>(type: T) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type as T),
    multi: true,
  };
}
