<div class="hierarchy-tabs">
  <div
    *ngFor="let item of tabs; let i = index"
    class="ht-block"
    [ngClass]="{ 'ht-selected': item.path === (item.ignoreSubPath ? ignoreSubBath(active) : active) }"
    [style.border-bottom]="item.path === (item.ignoreSubPath ? ignoreSubBath(active) : active) ? '3px solid ' + highlightColor : 'none'"
  >
    <a [routerLink]="item.path" [ngClass]="{ 'font-weight-bold': item.path === (item.ignoreSubPath ? ignoreSubBath(active) : active) }">
      {{ item?.title }}
      <i *ngIf="item.iconClass" [class]="item.iconClass" aria-hidden="true"></i>
    </a>
  </div>
</div>
