import { OVField, OVEntity, OVTable, OVSearch } from '@ov-suite/ov-metadata';
import { ProductSku } from '@ov-suite/models-admin';

@OVEntity({ name: 'OrderItem' })
@OVTable<OrderItem>([
  {
    key: 'productSku.name',
    title: 'Product Variant',
    type: 'deep-string',
  },
  {
    key: 'productSku.sku',
    title: 'SKU ID',
    type: 'deep-string',
  },
  {
    key: 'quantity',
    title: 'Qty(Items)',
    type: 'number',
    editable: true,
  },
  {
    keys: ['unitPriceExcl'],
    title: 'Amount',
    type: 'other',
    action: item => `${item.quantity * item.unitPriceExcl}`,
  },
  {
    title: 'Actions',
    type: 'buttons',
    hideColumnKey: 'actions',
    disableSorting: true,
    disableFiltering: true,
    keys: [],
    buttons: [
      {
        classes: 'btn btn-dark btn-sm mx-1',
        icon: 'fa fa-check',
        action: (item, { apiService, ngbModal }) => {
          console.log('Here: ', item);
          apiService
            .update({ entity: OrderItem, item: { id: item.id, quantity: item.quantity } })
            .then(response => {
              alert('Order Item Updated');
            })
            .catch(error => console.log({ error }));
        },
      },
      {
        classes: 'btn btn-dark btn-sm mx-1',
        icon: 'fa fa-trash',
        actionType: 'delete',
      },
    ],
  },
])
@OVSearch<OrderItem>({ searchKeys: ['id', 'productSku.name'] })
export class OrderItem {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: 'number' })
  productSkuId: number;

  @OVField({ type: () => ProductSku })
  productSku: ProductSku;

  @OVField({ type: 'number' })
  orderId: number;

  @OVField({ type: 'number' })
  unitPriceExcl: number;

  @OVField({ type: 'number' })
  unitPriceIncl: number;
}
