import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';
import { AddressModel } from '@ov-suite/models-shared';
import { Area } from './area.model';
import { Domain } from './domain.model';

@OVEntity('CustomerStatus', 'shared')
export class CustomerStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Customer', 'shared')
@OVForm([
  ['#Customer Details'],
  ['customerCode', 'name'],
  ['description'],
  ['mobileNumber', 'primaryEmail'],
  ['area'],
  ['leadTinee'],
  ['#Physical Address'],
  ['physicalAddress'],
  ['#Postal Address'],
  ['postalAddress'],
  ['#Default Delivery Address'],
  ['defaultDeliveryAddress'],
  ['#Other'],
  ['specialInstructions'],
  ['domains'],
])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<Customer>([
  {
    key: 'customerCode',
    title: 'Customer ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Customer Name',
    type: 'string',
  },
  // {
  //   key: 'address',
  //   title: 'Customer Address',
  //   type: 'string',
  // },
  {
    type: 'other',
    title: 'Area',
    action: item => item?.area?.name || 'None',
    keys: ['area.name'],
    disableFiltering: true,
  },
])
@OVSearch<Customer>({ searchKeys: ['customerCode', 'name', 'mobileNumber', 'primaryEmail', 'area.name', 'area.areaId'] })
export class Customer {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Customer Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Customer ID',
    tooltip: 'Used as a unique key for identification throughout the site.',
    placeholder: 'Required',
    required: true,
  })
  customerCode: string;

  @OVSearchable()
  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVSearchable()
  @OVField({ type: 'string', title: 'Customer Mobile Number' })
  mobileNumber?: string;

  @OVSearchable()
  @OVField({ type: 'string', title: 'Customer Email Address' })
  primaryEmail?: string;

  // @OVField({ type: 'string', title: 'Customer Address' })
  // address?: string;

  // @OVField({
  //   type: 'map',
  //   coordinatesKey: 'coordinates',
  //   addressKey: 'address',
  //   required: false,
  //   bulkIgnore: true,
  // })
  // map: MapLocation;

  @OVField({ type: 'string', title: 'Special Instructions' })
  specialInstructions?: string;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: true,
    readonly: true,
    generated: true,
  })
  domains: Domain[];

  @OVField({
    type: () => Area,
    tooltip: 'Add a single area for this customer.',
    selectionType: 'single',
    title: 'Area',
    required: false,
  })
  area: Area;

  @OVField({
    type: () => CustomerStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: CustomerStatus;

  @OVField({ type: 'number' })
  creditLimit: number;

  // @OVField({ type: () => SalesRepModel })
  // salesRep: SalesRepModel;

  @OVField({ type: 'map' })
  defaultDeliveryAddress: AddressModel;

  @OVField({ type: 'map' })
  physicalAddress: AddressModel;

  @OVField({ type: 'map' })
  postalAddress: AddressModel;

  @OVField({
    type: 'number',
    title: 'Default Order Lead Time',
    tooltip: 'This is the default amount of days after order date that an order is expected to be delivered',
    placeholder: 'days',
  })
  leadTime: number;
}
