import { OVField, OVTable, OVEntity } from '@ov-suite/ov-metadata';
import { User } from '@ov-suite/models-idm';
import { Resource } from './resource.model';

@OVEntity('ResourceException', 'warehouselink')
@OVTable<ResourceExceptionModel>([
  // {
  //   key: 'id',
  //   title: 'ID',
  //   type: 'string',
  // },
  {
    title: 'Reporter',
    type: 'other',
    keys: ['reporter.firstName', 'reporter.lastName'],
    action: e => `${e.reporter.firstName} ${e.reporter.lastName}`,
  },
  {
    title: 'Name',
    type: 'deep-string',
    key: 'resource.name',
  },
  {
    title: 'Detail',
    type: 'string',
    key: 'detail',
  },
  {
    title: 'Time',
    type: 'date-time',
    key: 'createDate',
  },
])
export class ResourceExceptionModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  detail: string;

  @OVField({ type: 'date-time' })
  createDate: Date;

  @OVField({ type: () => User })
  reporter: User;

  @OVField({ type: 'string' })
  source?: string;

  @OVField({ type: () => Resource })
  resource: Resource;
}
