import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { Constructor, FieldMetadata } from '@ov-suite/ov-metadata';
import { ColumnData, parseParameters, QueryParam, QueryParams } from '@ov-suite/helpers-shared';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

export interface FilterChange {
  filter: Record<string, QueryParams[]>;
  query: Record<string, QueryParams[]>;
  search: Record<string, QueryParams[]>;
}

@Component({
  selector: 'ov-suite-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent<T = unknown> implements OnInit {
  @ViewChild(NgbDropdown) private readonly dropdown: NgbDropdown;

  @ViewChild('tagInput') tagInputRef: ElementRef;

  // @Input() dataSources: DataSources<GenericHierarchy> = {};

  @Input() formClass: Constructor<T>;

  @Input() filterEnabled = true;

  filteredColumns: string[] = [];

  @Input() searchDropdownPosition = 'bottom';

  // Used for localstorage of hidden columns. Required if hidden columns is used
  @Input() keyStore: string;

  // Callback when Filter Changes
  @Output() filterChange = new EventEmitter<FilterChange>();

  @Output() searchChange = new EventEmitter<Event>();

  @Output() clearSearch = new EventEmitter<FilterChange>();

  // ColumnData. Required
  columnData: ColumnData<T>[];

  @Input() dropdownData = {};

  @Input() metadata: FieldMetadata<T>;

  filteredColumnData: ColumnData<T>[];

  filterMapInput: Record<string, string[]> = {};

  filterMap: Record<string, QueryParams[]> = {};

  queryMap: Record<string, QueryParams[]> = {};

  searchMap: Record<string, QueryParams[]> = {};

  @Input() currentFilter: FilterChange;

  defaultHiddenKeys = [];

  dropdownOpen: boolean;

  // Array of hidden Fields
  @Input()
  set hideColumnKeys(event: string[]) {
    this.defaultHiddenKeys = event;
    if (!!event && event.length) {
      this.columnData = this.columnData.filter(col => !this.defaultHiddenKeys.some(dhk => dhk === col.hideColumnKey || dhk === col.id));
    }
  }

  constructor(
    @Inject('DEFAULT_API') private readonly defaultApi: string,
    public readonly ovAutoService: OvAutoService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.columnData = this.metadata?.table;

    if (this.keyStore) {
      try {
        this.filteredColumns = JSON.parse(localStorage.getItem(this.keyStore)) ?? this.getDefaultFilteredColumns();
        if (this.filteredColumns.length) {
          this.filteredColumnData = this.columnData.filter(col => !this.filteredColumns.includes(col.id));
        } else {
          this.filteredColumnData = this.columnData;
        }
      } catch (e) {
        localStorage.removeItem(this.keyStore);
        this.filteredColumnData = this.columnData;
      }
    } else {
      this.filteredColumnData = this.columnData;
    }

    if (!this.columnData) {
      throw new TypeError("'columnData' is required");
    }

    this.route.queryParamMap.subscribe(params => {
      if (params) {
        this.filterMap = parseParameters(QueryParam.filter, params);
        this.queryMap = parseParameters(QueryParam.query, params);
        // @ts-ignore
        this.filterMapInput = { ...this.filterMap, ...this.queryMap };
      }
    });
  }

  clearSearchFields() {
    const output: FilterChange = {
      filter: {},
      query: {},
      search: {},
    };
    this.currentFilter = output;
    this.clearSearch.emit(this.currentFilter);
    this.dropdown.close();
  }

  getDefaultFilteredColumns(): string[] {
    return this.columnData.filter(col => col.startHidden || this.defaultHiddenKeys.includes(col.hideColumnKey)).map(col => col.id);
  }

  onFilterChange(columnData: ColumnData<T>, event) {
    const value = event?.target?.value || '';
    switch (columnData.type) {
      case 'buttons':
        break;
      case 'column':
        break;
      case 'other':
        columnData.keys.forEach(key => {
          if (value) {
            this.searchMap[`${QueryParam.search}:${key}`] = [value];
          } else {
            delete this.searchMap[key];
          }
        });
        break;
      case 'date':
        const dateKey = (columnData.filterKey as string) ?? (columnData.key as string);
        // @ts-ignore
        this.queryMap[`${QueryParam.filter}:${dateKey}`] = [moment(value || event).format('YYYY-MM-DD')];
        break;
      case 'time':
        const timeKey = (columnData.filterKey as string) ?? (columnData.key as string);
        this.queryMap[`${QueryParam.filter}:${timeKey}`] = [value];
        break;
      case 'number':
        const numberKey = (columnData.filterKey as string) ?? (columnData.key as string);
        this.queryMap[`${QueryParam.filter}:${numberKey}`] = [value];
        break;
      case 'status':
        this.filterMap[`${QueryParam.filter}:status.name`] = [value];
        break;
      case 'pills':
        break;
      default:
        const defaultKey = (columnData.filterKey as string) ?? (columnData.key as string);
        this.searchMap[`${QueryParam.search}:${defaultKey}`] = [value];
    }
    const output: FilterChange = {
      filter: this.filterMap,
      query: this.queryMap,
      search: this.searchMap,
    };
    this.currentFilter = output;
  }

  clearFilter() {
    this.currentFilter = {
      search: {},
      query: {},
      filter: {},
    };
    // this.clearSearch.emit();
    this.filterChange.emit(this.currentFilter);
  }

  onFilterApply() {
    this.filterChange.emit(this.currentFilter);
    this.dropdown.close();
  }

  onSearchChange(event: Event): void {
    this.searchChange.emit(event);
  }

  onClearSimpleSearch() {
    this.clearSearch.emit();
  }

  setValueByKey(item, key) {
    const itemKey = `${key}.id`;
    if (item) {
      const filter = { ...this.currentFilter?.filter, [itemKey]: [item['id']] };
      const output: FilterChange = {
        filter,
        query: this.currentFilter?.query || {},
        search: this.currentFilter?.search || {},
      };
      this.currentFilter = output;
    }
  }
}
