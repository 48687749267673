<div class="col-md-12">
  <div class="row mt-2 mb-1">
    <div class="col-md-4">
      <h5 class="mb-0 text-primary">{{ barTitle }}</h5>
    </div>
    <div class="col-md-4">
      <div class="w-50 ml-25">
        <div class="input-group no-border navbar-search mb-0" *ngIf="showSearch">
          <input class="form-control" placeholder="Search..." (change)="onSearchChange($event)" />
          <div class="input-group-append">
            <div class="input-group-text">
              <i aria-hidden="true" class="fa fa-search"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="float-right">
        <button *ngIf="hideBulkUpload" class="btn-link text-dark" (click)="trigger = trigger + 1">
          <span class="float-right mr-2"><i aria-hidden="true" class="fa fa-cloud-download"></i></span>
          <span aria-hidden="true" class="d-none">
            <ov-suite-bulk-upload
              [trigger]="trigger"
              [metadata]="metadata"
              [className]="constructorName"
              [filterQuery]="filterQuery"
              (hasUploaded)="uploaded($event)"
            >
            </ov-suite-bulk-upload>
          </span>
        </button>
        <button class="btn-link text-dark" (click)="onCustomize()">
          <span class="float-right mr-2">
            <i *ngIf="!customizing" aria-hidden="true" class="fa fa-pencil-square-o"></i>
            <i *ngIf="customizing" aria-hidden="true" class="fa fa-check-square-o text-success nc-bold"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #calendar>
  <div class="m-3">
    <ov-suite-input type="date" (ngModelChange)="selectedDate($event)"></ov-suite-input>
  </div>
</ng-template>
