import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { UsersRoutes } from './users.routing';
import { UserListComponent } from './list/user-list.component';
import { UserAddOrEditComponent } from './add-or-edit/user-add-or-edit.component';
import { UserSetPasswordModalComponent } from './user-set-password-modal/user-set-password-modal.component';
import { EmailValidator } from './email.validator';
import { PhoneValidator } from './phone.validator';
import { PinValidator } from './pin.validator';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(UsersRoutes), FormsModule, UiModule],
  declarations: [UserListComponent, UserAddOrEditComponent, UserSetPasswordModalComponent],
  providers: [
    {
      provide: 'PIN_VALIDATOR',
      useClass: PinValidator,
    },
    {
      provide: 'EMAIL_VALIDATOR',
      useClass: EmailValidator,
    },
    {
      provide: 'PHONE_VALIDATOR',
      useClass: PhoneValidator,
    },
  ],
})
export class UsersModule {}
