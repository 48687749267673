import { OVEntity, OVField, OVSearch, OVTable } from '@ov-suite/ov-metadata';
import { InventoryLocation, VehicleClass } from '@ov-suite/models-admin';
import { ExternalVehicle } from './external-vehicle.model';
import { VehicleOverride } from './vehicle-override.model';
import { VehicleResource } from './vehicle-resource.model';
import { WaveInstance } from './wave-instance.model';
import { LoadOrderModel } from './load-order.model';
import { VehicleTemplate } from './vehicle-template.model';

@OVEntity({ name: 'LoadAllocation' })
@OVTable<LoadAllocation>([
  {
    type: 'number',
    key: 'id',
    title: 'Load ID',
  },
  {
    type: 'deep-string',
    key: 'vehicle.registration',
    title: 'Vehicle Registration',
    disableSorting: true,
    disableFiltering: true,
  },
  {
    type: 'deep-string',
    key: 'vehicle.class.name',
    title: 'Vehicle Class',
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'Wave',
    type: 'dropdown',
    key: 'wave',
    filterKey: 'wave.id',
    editable: true,
    disableSorting: true,
    disableFiltering: true,
    displayKeys: ['waveName'],
  },
  {
    type: 'string',
    key: 'id',
    title: 'Load Bay',
  },
  {
    type: 'date',
    key: 'date',
    title: 'Load Date',
    format: 'DD/MM/YYYY',
  },
  {
    type: 'string',
    key: 'id',
    title: 'Status',
  },
])
@OVSearch<LoadAllocation>({ searchKeys: ['vehicle.name', 'vehicle.registration', 'wave.name', 'loadBay.name'] })
export class LoadAllocation {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  date: string;

  @OVField({ type: () => ExternalVehicle })
  externalVehicle: ExternalVehicle;

  @OVField({ type: () => VehicleOverride })
  vehicle: VehicleOverride;

  @OVField({ type: () => [LoadOrderModel] })
  loadOrders: LoadOrderModel[];

  @OVField({ type: () => WaveInstance })
  wave: WaveInstance;

  @OVField({ type: () => InventoryLocation })
  loadBay: InventoryLocation;

  @OVField({ type: 'number' })
  loadBayId: number;

  @OVField({ type: 'boolean' })
  commit: boolean;

  @OVField({ type: 'date-time' })
  commitDate?: Date;

  @OVField({ type: 'date-time' })
  releaseDate?: Date;

  @OVField({ type: 'string' })
  pickSheetProcessId?: string;

  @OVField({ type: 'string' })
  domainIds: string;

  @OVField({ type: () => VehicleTemplate })
  vehicleTemplate: VehicleTemplate;

  static fromVehicle(vehicle: VehicleOverride, date: string): LoadAllocation {
    const output = new LoadAllocation();
    output.vehicle = vehicle;
    output.loadOrders = [];
    output.date = date;
    return output;
  }

  static fromExternalVehicle(vehicle: ExternalVehicle, date: string): LoadAllocation {
    const output = new LoadAllocation();
    output.externalVehicle = vehicle;
    output.loadOrders = [];
    output.date = date;
    return output;
  }

  setExternalVehicle(vehicle: ExternalVehicle) {
    this.externalVehicle = vehicle;
  }

  setVehicle(vehicleOverride: VehicleOverride) {
    this.vehicle = vehicleOverride;
  }

  isExternalVehicle(): boolean {
    return !!this.externalVehicle?.id;
  }

  getVehicle(): VehicleOverride {
    if (this.externalVehicle) {
      const output = new VehicleOverride();
      output.id = this.externalVehicle.id;
      output.name = `${this.externalVehicle.make} ${this.externalVehicle.model}`;
      output.class = {
        name: this.externalVehicle.vehicleClass,
        height: this.externalVehicle.height,
        width: this.externalVehicle.width,
        length: this.externalVehicle.length,
        weightLoadAllowed: this.externalVehicle.weightLimit,
        weightRedPercentage: 100,
        weightOrangePercentage: 90,
      } as VehicleClass;
      output.registration = this.externalVehicle.registration;
      output.resource = {
        startTime: this.externalVehicle.startTime,
        endTime: this.externalVehicle.endTime,
      } as unknown as VehicleResource;

      return output;
    }

    return this.vehicle;
  }
}
