/* eslint-disable */
export function Memo() {
  return function (target: any, key: any, descriptor: any) {
    const oldFunc = descriptor.value;
    const newFunc = memoize2(oldFunc);
    descriptor.value = function () {
      return newFunc.apply(this, arguments);
    };
  };
}

export function memoize(fn: any) {
  return function () {
    const args = Array.prototype.slice.call(arguments);
    const key = JSON.stringify(args);
    fn.cache = fn.cache || new Map<any, any>();
    if (fn.cache.has(key)) {
      return fn.cache.get(key);
    }
    const result = fn.apply(this, args);
    fn.cache.set(key, fn.apply(this, args));
    return result;
  };
}

export function memoize2(fn: any) {
  return function () {
    const args: any[] = Array.prototype.slice.call(arguments);
    fn.cache = fn.cache || new Map<any, any>();
    let currentMap: Map<any, any> = fn.cache;
    for (const arg of args) {
      const i = args.indexOf(arg);
      if (i === args.length - 1) {
        if (currentMap.has(arg)) {
          return currentMap.get(arg);
        } else {
          const result = fn.apply(this, args);
          currentMap.set(arg, fn.apply(this, args));
          return result;
        }
      } else {
        if (currentMap.has(arg)) {
          currentMap = currentMap.get(arg);
        } else {
          const newMap = new Map();
          currentMap.set(arg, newMap);
          currentMap = newMap;
        }
      }
    }
  };
}
