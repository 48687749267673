import { getTaggedClass, OVEntity, OVField, OVSearchable, OVTable } from '@ov-suite/ov-metadata';
import { PermissionType } from '@ov-suite/helpers-shared';

@OVEntity('ReasonEntity', 'shared')
@OVTable<Reason>([
  {
    type: 'number',
    title: 'ID',
    filterKey: 'id',
    key: 'id',
  },
  {
    title: 'Reason',
    type: 'string',
    filterKey: 'reason',
    key: 'reason',
  }
])
export class Reason {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Reason',
    placeholder: 'Required',
    required: true,
  })
  reason: string;


}
