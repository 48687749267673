<div class="background-cover" id="background-cover" (click)="outOfFocusClick()" *ngIf="isOpen"></div>
<div class="notification-container" [ngClass]="isOpen ? 'notification-container-open' : ''">
  <div class="notification-header">
    <label class="notification-label">Notifications - {{notifications.length}}</label>
    <a class="mark-all" (click)="markAllAsRead()" >Mark all as Read</a>
  </div>
  <div class="notification-menu" *ngIf="!!notifications && notifications.length > 0">
    <div
      class="notification-item read"
      [id]="notify.id"
      (click)="getLink(notify)"
      *ngFor="let notify of notifications"
    >
<!--      Removed for now-->
<!--      <div class="notification-clear cursor-pointer" (click)="clearNotification(notify)">-->
<!--        <i class="fa fa-close"></i>-->
<!--      </div>-->
      <div class="{{getClass(notify)}}">
        {{notify?.message}}
      </div>
      <div class="{{getClass(notify)}} notification-age">
        {{getNotificationAge(notify)}}
      </div>
      <hr>
    </div>
  </div>
  <div class="notification-menu" *ngIf="!!notifications && notifications.length === 0">
    <div class="notification-item">
      <div class="empty-notification">
        You currently have no notifications...
      </div>
    </div>
  </div>
  <div class="notification-menu" >
    <div class="recent-activity" [routerLink]="['/notifications']" (click)="close()">
      <div class="empty-notification">
        See all recent activity
      </div>
    </div>
  </div>
</div>
