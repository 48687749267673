import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { OvAutoService } from '@ov-suite/services';
import { GenericHierarchy } from '@ov-suite/ov-metadata';
import { HierarchyListComponent } from '../../hierarchy-list.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

type GenericHierarchyType = GenericHierarchy;

@Component({
  selector: 'ov-suite-truda-hierarchy-list',
  templateUrl: './truda-hierarchy-list.component.html',
  styleUrls: ['./truda-hierarchy-list.component.scss'],
})
export class TrudaHierarchyListComponent<T extends GenericHierarchyType> extends HierarchyListComponent<T> {
  constructor(
    private readonly trudaListRoute: ActivatedRoute,
    private readonly trudaListRouter: Router,
    private readonly trudaListLocation: Location,
    private readonly trudaListAutoService: OvAutoService,
    ngbModal: NgbModal,
  ) {
    super(trudaListRoute, trudaListRouter, trudaListLocation, trudaListAutoService, ngbModal);
  }
}
