<div class="wrapper d-flex vh-100 vw-100">
  <div class="col container vh-100">
    <div class="content">
      <img class="my-4 logo-header" src="../../../assets/img/linksuite/linksuite-logo.svg" height="97" width="265" alt="Link Logo" />

      <div class="mb-2">
        <div *ngIf="loading" class="loader-left loader"></div>
        <!--      header component-->
        <p class="header-top" *ngIf="!forgotPassword">
          Welcome to Link Suite. <br />
          Please login.
        </p>

        <p class="header-top" *ngIf="forgotPassword">
          Welcome to Link Suite. <br />
          Forgot Password?
        </p>

        <h6>
          <small *ngIf="forgotPassword"> Enter your email and verification code. </small>
        </h6>
      </div>

      <!--      form component-->
      <div class="form-container" *ngIf="!federated && !loading">
        <div class="input-group" [ngClass]="{ 'input-group-focus': focus === true }">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fa fa-user-circle" aria-hidden="true"></i>
            </span>
          </div>
          <input
            name="username"
            required
            [(ngModel)]="user.email"
            type="text"
            class="form-control"
            placeholder="Email Address"
            (focus)="focus = true"
            (blur)="focus = false"
          />
        </div>
        <div class="input-group" [ngClass]="{ 'input-group-focus': focus1 === true }" *ngIf="!forgotPassword">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i aria-hidden="true" class="fa fa-key fa-rotate-90"></i>
            </span>
          </div>
          <input
            id="password-input"
            name="password"
            required
            [(ngModel)]="password"
            (keyup.enter)="signIn()"
            [type]="passwordVisible ? 'value' : 'password'"
            placeholder="Password"
            class="form-control border-right-0 rounded-0"
            (focus)="focus1 = true"
            (blur)="focus1 = false"
          />
          <div class="input-group-append">
            <span class="input-group-text" [ngClass]="{ 'password-visibility-focus': focus1 === true }">
              <i (click)="togglePasswordVisibility()" [ngClass]="passwordVisible === true ? 'fa fa-times' : 'fa fa-eye'" aria-hidden="true">
              </i>
            </span>
          </div>
        </div>

        <div
          class="input-group remember-me"
          [ngClass]="{ 'input-group-focus': focus1 === true }"
          *ngIf="!forgotPassword && authState != 'NEW_PASSWORD_REQUIRED'"
        >
          <div class="d-flex align-items-center form-check-label">
            <input type="checkbox" />
            <span class="form-check-sign font-weight-bold ml-3"> Remember me </span>
          </div>
        </div>

        <div class="input-group" [ngClass]="{ 'input-group-focus': focus === true }" *ngIf="forgotPassword">
          <div *ngIf="!!user.email" class="input-group-prepend">
            <span class="input-group-text">
              <i aria-hidden="true" class="nc-icon nc-single-02"></i>
            </span>
          </div>
          <input
            *ngIf="!!user.email"
            name="verificationCode"
            required
            [(ngModel)]="verificationCode"
            type="text"
            class="form-control"
            placeholder="Verification Code"
          />
        </div>
        <div
          class="input-group"
          [ngClass]="{ 'input-group-focus': focus1 === true }"
          *ngIf="authState === 'NEW_PASSWORD_REQUIRED' || (forgotPassword && !!verificationCode)"
        >
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i aria-hidden="true" class="fa fa-key"></i>
            </span>
          </div>
          <input
            type="password"
            id="newPassword"
            class="form-control"
            [(ngModel)]="newPassword"
            placeholder="Please create a new Password"
            required
            (ngModelChange)="confirmPasswordsMatch()"
          />
          <span aria-hidden="true" class="fa fa-info-circle ml-1" placement="right-top" [ngbTooltip]="passwordPolicyText"></span>
        </div>
        <div
          class="input-group"
          [ngClass]="{ 'input-group-focus': focus1 === true }"
          *ngIf="authState === 'NEW_PASSWORD_REQUIRED' || (forgotPassword && !!verificationCode)"
        >
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i aria-hidden="true" class="fa fa-key"></i>
            </span>
          </div>
          <input
            type="password"
            id="confirmPassword"
            class="form-control"
            [(ngModel)]="confirmPassword"
            placeholder="Please confirm your new Password"
            required
            (ngModelChange)="confirmPasswordsMatch()"
          />
        </div>
        <span *ngIf="authState === 'NEW_PASSWORD_REQUIRED' || (forgotPassword && !!verificationCode)">
          <div class="pull-right">
            <small *ngIf="!passwordsMatch && password && confirmPassword" class="mt-2 text-danger">Passwords do not match.</small>
          </div>
        </span>
        <span class="bmd-form-group" *ngIf="requiredResponse.includes('name')">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons" aria-hidden="true">face</i>
              </span>
            </div>
            <input
              type="text"
              id="name"
              class="form-control text-white"
              placeholder="Please add your first name"
              [(ngModel)]="user.name"
              (focusout)="user.name = user.name.trim()"
              required
            />
          </div>
        </span>
        <span class="bmd-form-group" *ngIf="requiredResponse.includes('family_name')">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons" aria-hidden="true">accessibility_new</i>
              </span>
            </div>
            <input
              type="text"
              id="familyName"
              class="form-control text-white"
              placeholder="Please add your family name"
              [(ngModel)]="user.familyName"
              (focusout)="user.familyName = user.familyName.trim()"
              required
            />
          </div>
        </span>
        <span class="bmd-form-group" *ngIf="requiredResponse.includes('phone_number')">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="material-icons" aria-hidden="true">mobile_friendly</i>
              </span>
            </div>
            <input
              type="text"
              id="phoneNumber"
              class="form-control text-white"
              placeholder="Please add your phone number"
              [(ngModel)]="user.phoneNumber"
              required
            />
          </div>
        </span>

        <div *ngIf="!federated && !loading" class="col mt-4">
          <div class="row button-content-container">
            <button
              (click)="signIn()"
              type="button"
              class="btn btn-primary btn-round btn-lg btn-block w-100 m-0"
              *ngIf="!forgotPassword || newPassword"
              [disabled]="!user.email || (forgotPassword && !passwordsMatch) || !password"
            >
              Get Started <i class="ml-1 fa fa-arrow-right float-right text-center mt-1" aria-hidden="true"> </i>
            </button>
            <button
              (click)="forgotPasswordClick()"
              type="button"
              class="btn btn-primary btn-round btn-lg btn-block mb-3"
              *ngIf="forgotPassword && !newPassword"
              [disabled]="!user.email || verificationCode"
            >
              Get Verification Code
            </button>
          </div>
          <div class="row button-footer-container">
            <div class="col d-flex justify-content-start btn-link px-0 py-2">
              <a (click)="forgotPassword = true" class="font-weight-bold"> Need help? </a>
            </div>
            <div class="col d-flex justify-content-end btn-link px-0 py-2" *ngIf="!federated && !loading">
              <a (click)="forgotPassword = true" class="font-weight-bold"> Forgot password? </a>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="federated">
        <button (click)="federatedSignIn()" type="button" class="btn btn-primary btn-round btn-lg btn-block mb-3">
          {{ federatedText }}
        </button>
      </div>
      <div class="d-flex align-content-end font-weight-bold mb-3 mt-5">This Product is Part of the Link Suite.</div>
    </div>
  </div>
  <div class="col"></div>
</div>
