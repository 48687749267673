export type QueryParams = string | number | QueryDateRange | AdvancedQuery;

type Operator = '=' | '!=' | '<>' | '>' | '<' | '>=' | '<=' | 'IS' | 'IS NOT';

export interface AdvancedQuery {
  value: string | number | null | boolean | Date;
  operator: Operator;
}

export interface QueryDateRange {
  type: 'date-range';
  from?: Date | null;
  to: Date | null;
}

export function isDateRange(input: QueryParams): input is QueryDateRange {
  return (input as QueryDateRange)?.type === 'date-range';
}

export function isAdvancedQuery(input: QueryParams): input is AdvancedQuery {
  return !!(input as AdvancedQuery)?.operator;
}
