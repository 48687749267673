import { OVField, OVForm, OVTable, OVSearchable, OVEntity } from '@ov-suite/ov-metadata';
import { Domain } from '@ov-suite/models-admin';

@OVEntity('ResourceType', 'warehouselink')
export class ResourceType {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  value: string;

  @OVField({ type: 'string' })
  key: string;

  @OVField({ type: 'string' })
  description: string;
}

@OVEntity('Resource', 'warehouselink')
@OVForm([['#Resource Details'], ['name', 'description'], ['domains', 'type'], ['barcodeData', ''], ['qrPreview']])
@OVTable<Resource>([
  {
    key: 'id',
    title: 'ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Resource Name',
    type: 'string',
  },
  // {
  //   key: 'type',
  //   title: 'Resource Type',
  //   type: 'other',
  //   keys: ['type', 'type.value'],
  //   action: resource => resource.type.value,
  // },
  {
    title: 'Resource Type',
    type: 'deep-string',
    key: 'type.value',
  },
])
export class Resource {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Resource Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Description',
    placeholder: 'Required',
    required: true,
  })
  description: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Created By',
    placeholder: 'Required',
    required: false,
  })
  createdBy?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Type',
    placeholder: 'Required',
    required: false,
  })
  barcodeType?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Data',
    placeholder: 'Required',
    required: true,
  })
  barcodeData?: string;

  @OVField({
    type: () => ResourceType,
    selectionType: 'simple',
    flat: true,
    dropdownKeys: ['value'],
    keys: ['id', 'key', 'value'],
    title: 'Type',
    required: true,
  })
  type: ResourceType;

  @OVField({
    type: 'code-preview',
    unnecessary: true,
    codeKey: 'barcodeData',
  })
  qrPreview: void;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: false,
    readonly: true,
    generated: true,
  })
  domains: Domain[];
}
