import { OVField, OVEntity } from '@ov-suite/ov-metadata';
import { Order } from './order.model';
import { User } from '@ov-suite/models-idm';

@OVEntity({ name: 'OrderComment' })
export class OrderCommentModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  message: string;

  @OVField({ type: () => Order })
  order: Order;

  @OVField({ type: () => User })
  user: User;

  @OVField({ type: 'date-time' })
  timestamp: string;

  @OVField({ type: () => OrderCommentModel })
  target?: OrderCommentModel;

  @OVField({ type: () => [OrderCommentModel] })
  replies: OrderCommentModel[];
}
