import { Component, EventEmitter, Injector, Input, OnInit, Output, ReflectiveInjector } from '@angular/core';
import type { ColumnDataButtons } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-buttons-cell',
  template: `

    <div class="btn-group" role="group" aria-label="Basic example">
      <ng-container *ngFor="let button of col?.buttons">
        <button
          (click)="click.emit(button)"
          type="button"
          class="btn btn-secondary table-button action-btn-tooltip"
          [style.display]="button.hide ? (button.hide(item) ? 'none' : 'block') : 'block'"
          [ngClass]="getObjectType(button.classes) === 'function' ? button.classes(item) : button.classes"
          *ngIf="!button.modal"
        >
          {{ getObjectType(button?.title) === 'function' ? button?.title(item) : button?.title }}
          <i *ngIf="button?.icon" [class]="button.icon" aria-hidden="true"></i>
          <span *ngIf="button.tooltip" class="action-btn-tooltip-text" [class]="button.tooltipClass">{{ button.tooltip }}</span>
        </button>

        <!--Button for ngModel-->
        <div *ngIf="button.modal" ngbDropdown display="dynamic" placement="{{ modalDisplayPosition }}">
          <button
            (click)="click.emit(button)"
            type="button"
            class="btn btn-secondary table-button action-btn-tooltip"
            [style.display]="button.hide ? (button.hide(item) ? 'none' : 'block') : 'block'"
            [ngClass]="getObjectType(button.classes) === 'function' ? button.classes(item) : button.classes"
            ngbDropdownToggle
          >
            {{ getObjectType(button?.title) === 'function' ? button?.title(item) : button?.title }}
            <i *ngIf="button?.icon" [class]="button.icon" aria-hidden="true"></i>
            <span *ngIf="button.tooltip" class="action-btn-tooltip-text">{{ button.tooltip }}</span>
          </button>
          <div ngbDropdownMenu>
            <ng-container *ngComponentOutlet="button.modal; injector: myInjector"> </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  `,
  styleUrls: ['../../basic-table.component.scss'],
})
export class ButtonsCellComponent<T> implements OnInit {
  @Input() item: T;
  @Input() col: ColumnDataButtons<T>;
  @Input() modalDisplayPosition: string;
  @Output() click = new EventEmitter();

  myInjector: Injector;

  constructor(private readonly injector: Injector) {}

  ngOnInit() {
    this.myInjector = Injector.create({
      parent: this.injector,
      providers: [{ provide: 'item', useValue: this.item }],
    });
  }

  getObjectType(type: unknown): string {
    return typeof type;
  }
}
