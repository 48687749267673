<div class="row date-range-container">
  <button class="form-control range-button" (click)="datepicker.toggle()" type="button">
    {{ !!displayDate ? displayDate : _placeholder }}
  </button>
  <button *ngIf="!hideClearButton && (fromDate || _placeholder)" class="form-control close-button" (click)="clearDateRange()">X</button>
  <input
    name="datepicker"
    class="form-control dont-render"
    ngbDatepicker
    #datepicker="ngbDatepicker"
    [autoClose]="'outside'"
    (dateSelect)="onDateSelection($event)"
    [displayMonths]="1"
    [dayTemplate]="t"
    outsideDays="hidden"
    [startDate]="fromDate!"
  />
  <ng-template #t let-date let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.range]="isRange(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>
</div>
