import { OVField, OVEntity, OVTable, OVSearch } from '@ov-suite/ov-metadata';
import { Customer } from '@ov-suite/models-admin';
import { ReturnItem } from './return-item.model';
import { SalesRepModel } from './sales-rep.model';

@OVEntity({ name: 'Return' })
@OVTable<ReturnModel>([
  {
    key: 'id',
    title: 'Return Order ID',
    type: 'number',
  },
  {
    key: 'createDate',
    title: 'Date Created',
    type: 'date',
    format: 'DD/MM/YYYY hh:mm:ss A',
  },
  {
    title: 'Customer ID',
    type: 'other',
    keys: ['customer', 'customer.id'],
    action: col => col?.customer?.customerCode,
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'Customer Name',
    type: 'other',
    keys: ['customer.name'],
    action: order => order?.customer?.name,
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'Return Amount',
    type: 'other',
    keys: ['returnItems.price'],
    action: col =>
      col?.returnItems
        .reduce((prev, item) => {
          prev += item.price;
          return prev;
        }, 0)
        .toFixed(2),
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'Products Returned',
    type: 'other',
    keys: ['returnItems'],
    action: col => col?.returnItems.length.toString(),
    disableSorting: true,
    disableFiltering: true,
  },
  // {
  //   title: 'Status',
  //   filterKey: 'status',
  //   disableFiltering: true,
  //   keys: ['priority', 'orderItems'], // using orderItems for the popup
  //   type: 'other',
  //   action: order => `<span [class]="'badge '+ ${ReturnPriorityConstant[order?.status]?.color}">
  //           ${ReturnPriorityConstant[order?.status]?.text} </span>`,
  // },
])
@OVSearch<ReturnModel>({ searchKeys: ['id', 'customer.name'] })
export class ReturnModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'number' })
  customerId: number;

  @OVField({ type: 'number' })
  salesRepId: number;

  @OVField({ type: () => Customer })
  customer: Customer;

  @OVField({ type: 'date-time' })
  createDate: string;

  @OVField({ type: () => SalesRepModel })
  salesRep: SalesRepModel;

  @OVField({
    type: () => [ReturnItem],
    keys: ['id', 'quantity', 'productSku.id', 'productSku.sku', 'productSku.name', 'productSkuId'],
  })
  returnItems?: ReturnItem[];
}
