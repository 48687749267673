import { getTaggedClass, OVEntity, OVField, OVSearchable, OVTable } from '@ov-suite/ov-metadata';
import { PermissionType } from '@ov-suite/helpers-shared';

@OVEntity('InventoryProductSkuStatus', 'warehouselink')
export class InventoryProductSkuStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;

  @OVField({ type: 'number' })
  quantity: number;
}

@OVEntity('InventoryProductSku', 'warehouselink')
@OVTable<InventoryProductSku>([
  {
    key: 'id',
    title: 'ID',
    type: 'number',
  },
  {
    key: 'sku',
    title: 'SKU',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    title: 'Weight',
    type: 'other',
    action: item => (item?.weight ? `${item.weight} g` : ''),
    keys: ['weight'],
  },
  {
    title: 'Dimensions',
    type: 'other',
    action: item => {
      const message = `${item?.length} cm x ${item?.height} cm x ${item?.width} cm`;
      return message.includes('null') ? '' : message;
    },
    keys: ['length', 'height', 'width'],
  },
  {
    title: 'On Hand',
    type: 'other',
    action: item => {
      return `<i class="fa fa-circle text-${item?.inventoryLevelStatus?.color}" aria-hidden="true"></i> <span> ${item.inventoryLevelStatus?.quantity} </span>`;
    },
    keys: ['inventoryLevelStatus', 'inventoryLevelStatus.quantity', 'inventoryLevelStatus.color'],
  },
  {
    title: 'Actions',
    keys: [],
    type: 'buttons',
    buttons: [
      {
        classes: 'btn btn-primary btn-sm',
        action: (item, { ngbModal }) => {
          const modalRef = ngbModal.open(getTaggedClass('set-triggers-popup', InventoryProductSku), { size: 'lg' });
          modalRef.componentInstance.inventoryProductSku = item;
        },
        title: 'Set Triggers',
        permissionId: 107,
        permissionType: PermissionType.update,
      },
    ],
  },
])
export class InventoryProductSku {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'SKU',
    tooltip: 'Stock Keeping Unit.',
    placeholder: 'Required',
    required: true,
  })
  sku: string;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({
    type: () => InventoryProductSkuStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  inventoryLevelStatus: InventoryProductSkuStatus;

  @OVField({
    type: 'number',
    title: 'Weight',
    step: 'any',
    min: 0,
  })
  weight: number;

  @OVField({
    type: 'number',
    title: 'Length',
    step: 'any',
    min: 0,
  })
  length: number;

  @OVField({
    type: 'number',
    title: 'Height',
    step: 'any',
    min: 0,
  })
  height: number;

  @OVField({
    type: 'number',
    title: 'Width',
    step: 'any',
    min: 0,
  })
  width: number;
}
