import { OVEntity, OVField, OVForm, OVSearch, OVTable } from '@ov-suite/ov-metadata';
import { User } from '@ov-suite/models-idm';
import { Area, Customer, CustomerGroup, Domain } from '@ov-suite/models-admin';

@OVEntity({ name: 'SalesRep' })
@OVTable<SalesRepModel>([
  {
    key: 'id',
    title: 'ID',
    type: 'number',
  },
  {
    key: 'user.email',
    title: 'Login',
    type: 'deep-string',
  },
  {
    title: 'Name',
    type: 'other',
    action: sr => `${sr.user.firstName} ${sr.user.lastName}`,
    keys: ['user.firstName', 'user.lastName'],
  },
])
@OVForm([
  ['user'],
  ['customers'],
  ['customerGroups'],
  ['areas'],
  ['weeklyTarget', 'monthlyTarget'],
  ['quarterlyTarget', 'yearlyTarget'],
  ['domains', ''],
])
@OVSearch<SalesRepModel>({ searchKeys: ['id', 'customer.name', 'orderDate'] })
export class SalesRepModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({
    type: () => User,
    tooltip: 'Select the User',
    selectionType: 'single',
    title: 'User',
    flat: true,
    required: true,
    nameColumnKey: 'email',
  })
  user: User;

  @OVField({
    type: () => [Customer],
    tooltip: 'Assign Customers',
    selectionType: 'multiple',
    title: 'Customers',
    flat: true,
    required: false,
    keys: ['id', 'name'],
  })
  customers: Customer[];

  @OVField({
    type: () => [CustomerGroup],
    tooltip: 'Assign Customer Groups',
    selectionType: 'multiple',
    title: 'Customer Groups',
    flat: true,
    required: false,
    keys: ['id', 'name'],
  })
  customerGroups: CustomerGroup[];

  @OVField({
    type: () => [Area],
    tooltip: 'Assign Areas',
    selectionType: 'multiple',
    title: 'Areas',
    flat: true,
    required: false,
    keys: ['id', 'name'],
  })
  areas: Area[];

  @OVField({
    type: 'number',
    title: 'Weekly Target',
  })
  weeklyTarget: number;

  @OVField({
    type: 'number',
    title: 'Monthly Target',
  })
  monthlyTarget: number;

  @OVField({
    type: 'number',
    title: 'Quarterly Target',
  })
  quarterlyTarget: number;

  @OVField({
    type: 'number',
    title: 'Yearly Target',
  })
  yearlyTarget: number;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: false,
    readonly: true,
    generated: true,
  })
  domains: Domain[];
}
