import { Injectable } from '@angular/core';
import { FieldMetadata } from '@ov-suite/ov-metadata';
import XLSX from 'xlsx';
// import { File } from 'cross-fetch/lib.fetch';

@Injectable()
export class BulkUploadXlService {
  async create<T>(metadata: FieldMetadata<T>) {
    const keys: string[] = [];
    // const headers: string[] = [];
    const headers: Record<string, string> = {};
    const helpers: Record<string, string> = {};
    const colWidth: unknown[] = [];

    metadata.fields.forEach(f => {
      const key = f.title || f.propertyKey;
      const required = f.required ? '*' : '';
      const pushSpecial = (key: string, header: string, helper: string, force = false) => {
        keys.push(key);
        headers[key] = header;
        helpers[key] = helper;
        const calculatedWidth = 5 + header.length;
        colWidth.push({ width: force ? calculatedWidth : f.bulkColumnWidth ?? calculatedWidth });
      };
      const push = (header: string, helper: string, force = false) => {
        pushSpecial(f.propertyKey, header, helper, force);
      };
      if (!f.bulkIgnore && !f.generated) {
        switch (f.type) {
          case 'boolean':
            push(`${key}${required}`, 'Yes/No');
            break;
          case 'numbers':
          case 'number':
            push(`${key}${required}`, 'Number');
            break;
          case 'password':
          case 'string':
            push(`${key}${required}`, 'Text');
            break;
          case 'date':
            push(`${key}${required}`, 'Date');
            break;
          case 'date-time':
            push(`${key}${required}`, 'Date and Time');
            break;
          case 'domain-selector':
            push(`${key}${required}`, 'Key');
            break;
          case 'time':
            push(`${key}${required}`, 'Time');
            break;
          case 'image':
            push(`${key}${required}`, 'URL');
            break;
          case 'map':
            push(`${key}${required}`, 'Text');
            pushSpecial(`${f.propertyKey}.longitude`, `${key} Longitude${required}`, 'Coordinate', true);
            pushSpecial(`${f.propertyKey}.longitude`, `${key} Latitude${required}`, 'Coordinate', true);
            break;
          // Ignore These
          case 'blank':
          case 'button':
          case 'code-preview':
          case 'title':
          case 'recurrence':
          case 'permission':
          case 'json':
          default:
        }
      }
    });

    const worksheet = XLSX.utils.json_to_sheet([headers, helpers], {
      header: keys,
    });
    worksheet['!cols'] = colWidth;
    worksheet['!rows'] = [{ hidden: true }];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
    XLSX.writeFile(workbook, `${metadata.name}_Template.xlsx`);
  }

  async getDataFromFile<T>(file: unknown): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        if (e.target.result) {
          const workbook = XLSX.read(e.target.result, { type: 'binary' });
          const json = XLSX.utils.sheet_to_json<T>(workbook.Sheets.SheetJS, { blankrows: false });
          resolve(json.slice(2));
        } else {
          reject();
        }
      };
      //@ts-ignore
      reader.readAsBinaryString(file);
    });
  }
}
