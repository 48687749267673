import { OVEntity, OVField, OVForm, OVSearch, OVSearchable, OVSidebar, OVTable } from '@ov-suite/ov-metadata';
import { DayMapper, getNumberWithOrdinal, Recurrence, WeekDay } from '@ov-suite/helpers-shared';
import moment from 'moment';
import { Domain } from '@ov-suite/models-admin';

@OVEntity('WaveConfig', 'warehouselink')
@OVForm([
  ['#Picking Waves'],
  ['waveName'],
  ['description'],
  ['domains'],
  ['#Start'],
  ['startDate'],
  ['startTime'],
  ['endTime'],
  ['#Frequency'],
  ['recurring'],
  ['recurrence'],
])
@OVSidebar([['id']])
@OVTable<WaveConfig>([
  {
    key: 'waveName',
    title: 'Name',
    type: 'string',
  },
  {
    key: 'createdAt',
    title: 'Date Created',
    type: 'date',
  },
  {
    title: 'Wave Date & Time',
    type: 'other',
    keys: ['startDate', 'startTime'],
    id: 'waveDateTime',
    filterKey: 'waveDateTime',
    disableFiltering: true,
    disableSorting: true,
    action: wave => `${moment(wave.startDate).format('dddd , MMMM Do')} - ${moment(wave.startTime, 'HH:mm:ss').format('hh:mm')}`,
  },
  {
    title: 'Recurring',
    type: 'other',
    filterKey: 'recurrence',
    keys: ['recurrence'],
    disableSorting: true,
    disableFiltering: true,
    action: r => {
      const data = r.recurrence as unknown as Recurrence;

      if (data) {
        switch (data.type) {
          case 'daily': {
            if (data.repeat === 1) {
              return 'Daily';
            }
            return `Every ${getNumberWithOrdinal(data.repeat)} day`;
          }
          case 'weekly': {
            if (data.days === 127 && data.repeat === 1) {
              return 'Daily';
            }

            if (data.days === 127 && data.repeat > 1) {
              return `Every ${getNumberWithOrdinal(data.repeat)} Week Daily`;
            }

            const days: string[] = [];
            Object.entries(DayMapper).forEach(([key, day]) => {
              if ((Number(key) | data.days) === data.days) {
                days.push(day);
              }
            });

            return `Every ${getNumberWithOrdinal(data.repeat)} on ${days.reverse().join(', ')}`;
          }
          case 'monthly': {
            const weekName = getNumberWithOrdinal(data.weekNumber);
            const dayName = getNumberWithOrdinal(data.day);
            const day = data.weekNumber ? `${weekName} week on ${WeekDay[data.day]}` : `${dayName}`;
            return `Monthly on the ${day}`;
          }
        }
      }

      return r.recurrence;
    },
  },
  {
    title: 'Fast Actions',
    keys: [],
    type: 'buttons',
    disableSorting: true,
    disableFiltering: true,
    buttons: [
      {
        classes: 'btn btn-primary btn-sm',
        icon: 'fa fa-pencil text-white',
        actionType: 'edit',
        action: (item, { columnAction }) => columnAction(),
        // permissionId: 101,
        // permissionType: PermissionType.update,
      },
      {
        classes: 'btn btn-primary btn-sm ml-1',
        icon: 'fa fa-trash text-white',
        actionType: 'delete',
        action: (item, { columnAction }) => columnAction(),
        // permissionId: 101,
        // permissionType: PermissionType.delete,
      },
      {
        classes: 'btn btn-primary btn-sm ml-1',
        routerLink: (item, url) => [`/waves/clone`],
        queryParams: (item: { id: number }) => ({ id: item.id }),
        title: '',
        actionType: 'routeLink',
        icon: 'fa fa-clone text-white',
        // permissionId: 101,
        // permissionType: PermissionType.create,
      },
    ],
  },
])
@OVSearch<WaveConfig>({ searchKeys: ['waveName'] })
export class WaveConfig {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Wave Name',
    placeholder: 'Required',
    required: true,
  })
  waveName: string;

  @OVField({
    type: 'date',
    title: 'Start Date',
    placeholder: 'Required',
    required: true,
  })
  startDate: Date;

  @OVField({
    type: 'string',
    title: 'Start Date',
    placeholder: 'Required',
    required: true,
  })
  startTime: string;

  @OVField({
    type: 'string',
    title: 'Start Date',
    placeholder: 'Required',
    required: true,
  })
  endTime: string;

  @OVField({
    type: 'date',
    title: 'Created At',
    placeholder: 'Required',
    required: false,
  })
  createdAt: Date;

  @OVField({
    type: 'boolean',
    title: 'Recurring',
    placeholder: 'Required',
    required: false,
  })
  recurring: boolean;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Template',
    placeholder: 'Required',
    required: false,
  })
  templateName: string;

  @OVField({
    type: 'json',
    title: 'Description',
    required: false,
  })
  recurrence?: string;

  @OVField({
    type: 'string',
    title: 'Description',
    required: false,
  })
  description: string;

  @OVField({
    type: () => [Domain],
    selectionType: 'multiple',
    title: 'Domains',
    required: true,
  })
  domains: Domain[];
}
