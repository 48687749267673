import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ColumnDataBoolean } from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-boolean-cell',
  template: `
    <span *ngIf="!col.editable">
      {{ item[col?.key] }}
    </span>
    <ov-suite-input
      *ngIf="col.editable"
      type="checkbox"
      [style]="{ height: '38px' }"
      [(ngModel)]="item[col?.key]"
      (ngModelChange)="itemChanged(item)"
    ></ov-suite-input>
  `,
})
export class BooleanCellComponent<T> {
  @Input() item: T;
  @Input() col: ColumnDataBoolean<T>;
  @Output() itemChange = new EventEmitter<T>();

  itemChanged(item: T): void {
    this.itemChange.emit(item);
  }
}
