export class Grouping {
  constructor(size: number, maxMultiples: number) {
    this.size = size;
    this.maxMultiples = maxMultiples;
  }

  size: number;

  maxMultiples: number;
}
