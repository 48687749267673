/* eslint-disable @typescript-eslint/naming-convention */
import { APP_INITIALIZER, Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { Domain } from '@ov-suite/models-admin';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  static domainKey = 'CURRENT_DOMAIN';
  original: Domain[] = [];
  list: Domain[] = [];
  map: Record<number, Domain> = {};
  domainListSubject: BehaviorSubject<Domain[]>;
  domainList: Observable<Domain[]>;
  currentDomainSubject: BehaviorSubject<Domain>;
  currentDomain: Observable<Domain>;

  constructor(private readonly ovAutoService: OvAutoService, private readonly cookieService: CookieService) {
    const domainPath = cookieService.get(DomainService.domainKey);
    // @ts-ignore
    const currentDomain: Domain = {
      id: Number(domainPath.split('.').reverse().slice(1)[0]),
      path: domainPath,
    };
    this.currentDomainSubject = new BehaviorSubject<Domain | null>(currentDomain);
  }

  public loadDomains(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.ovAutoService
        .list({
          entity: Domain,
          limit: 10000,
          keys: ['id', 'name', 'path', 'parent', 'children'],
        })
        .then(domainList => {
          this.list = domainList.data;
          this.map = domainList.data.reduce<Record<string, Domain>>((p, c) => {
            p[c.id] = c;
            return p;
          }, {});
          resolve(true);
        })
        .catch(() => {
          this.list = [];
          resolve(true);
        });
    });
  }

  async syncDomains() {
    await this.ovAutoService
      .list({
        entity: Domain,
        limit: 10000,
        keys: ['id', 'name', 'path'],
      })
      .then(domainList => {
        this.list = domainList.data;
      });
  }

  getParentDomains(): Domain[] {
    return this.list.filter(domain => domain.parent == null);
  }

  getById(id: number): Domain {
    return this.list.find(d => d.id == id);
  }

  getByPath(path: string): Domain {
    return this.list.find(d => d.path == path);
  }

  getByIds(ids: number[]): Domain[] {
    const results: Domain[] = [];
    ids.forEach(id => {
      const found = this.list.find(d => d.id == id);
      if (found) results.push(found);
    });
    return results;
  }

  getDomains(keyword: string = ''): Domain[] {
    return this.list.filter(x => x.name.includes(keyword));
  }

  getCurrentDomain(): Domain | undefined {
    const currentDomainId = this.getCurrentDomainId();
    if (this.map[currentDomainId]) {
      return this.map[currentDomainId];
    }
    const [firstDomain] = this.list;
    if (firstDomain) {
      this.cookieService.set(DomainService.domainKey, firstDomain.path);
    }
    return firstDomain;
  }

  getCurrentDomainId(): number | null {
    if (this.currentDomainSubject.value) {
      return this.currentDomainSubject.value.id;
    }
    return this.getByPath(this.cookieService.get(DomainService.domainKey)).id;
  }

  setCurrentDomainPath(domainPath: string) {
    this.cookieService.set(DomainService.domainKey, domainPath);
    this.currentDomainSubject.next(this.getByPath(domainPath));
  }
}

export function initializeAppWithDomains(domainService: DomainService) {
  return () => domainService.loadDomains();
}

export const DomainServiceInitializer = {
  provide: APP_INITIALIZER,
  useFactory: initializeAppWithDomains,
  deps: [DomainService],
  multi: true,
};
