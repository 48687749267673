import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { OrderItem } from '@ov-suite/models-order';

@OVEntity({ name: 'LoadOrderItem' })
export class LoadOrderItemModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: () => OrderItem })
  orderItem: OrderItem;
}
