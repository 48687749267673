/* eslint-disable no-bitwise */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { App, Feature, UserTypeFeature } from '@ov-suite/models-idm';
import { CRUD, getCrud, FeatureType } from '@ov-suite/helpers-shared';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';

interface FeatureLock {
  value: number;
  source: {
    id: number;
    value: number;
  }[];
}

@Component({
  selector: 'ov-suite-permission-matrix',
  templateUrl: './permission-matrix.component.html',
  styleUrls: ['./permission-matrix.component.scss'],
  providers: [MakeProvider(PermissionMatrixComponent)],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PermissionMatrixComponent extends AbstractValueAccessor<UserTypeFeature[]> implements OnInit {
  constructor(private readonly ovAutoService: OvAutoService) {
    super();
  }

  allApps: App[];

  featureType = FeatureType;

  featureLock: Record<string, CRUD> = {};

  // Tightly Coupled with featureLock
  featureLockDetail: Record<number, FeatureLock> = {};

  val: UserTypeFeature[] = [];

  helperMap: { [key: number]: UserTypeFeature } = {};

  writeValue(value: UserTypeFeature[]) {
    this.val = value;
    this.onChange(value);

    if (!value) {
      this.val = [];
    }
    this.val.forEach((userTypeFeature: UserTypeFeature) => {
      this.helperMap[userTypeFeature.feature.id] = userTypeFeature;
    });
  }

  ngOnInit() {
    this.ovAutoService.list({ entity: App, limit: 1000, orderColumn: 'name', orderDirection: 'ASC' }).then(res => {
      this.allApps = res.data.filter(app => app.features.length);
      this.allApps.forEach(app => {
        app.features.forEach(feature => {
          if (feature.defaultPermission) {
            this.lockFeature(feature.id, feature.defaultPermission, feature.id);
          }
        });
        app.features.sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
      });

      this.synchronise();
    });
  }

  synchronise() {
    if (this.allApps?.length) {
      const newFeatures = [];
      this.allApps.forEach(app => {
        app.features.forEach(feature => {
          if (!this.helperMap[feature.id]) {
            const newUserTypeFeature = new UserTypeFeature();
            newUserTypeFeature.feature = feature;
            newUserTypeFeature.permission = feature.defaultPermission ?? 0;
            newFeatures.push(newUserTypeFeature);
          }
        });
      });
      this.writeValue([...this.val, ...newFeatures]);
    }
  }

  hasPermission(value: number, column: 1 | 2 | 4 | 8): boolean {
    return (value | column) === value;
  }

  toggleAll(feature: Feature): void {
    this.helperMap[feature.id].permission = this.helperMap[feature.id].permission !== 15 ? 15 : feature.defaultPermission ?? 0;

    if (this.helperMap[feature.id].permission === 15) {
      feature.requirements?.forEach(req => {
        this.lockFeature(req.id, req.lock, feature.id);
        this.helperMap[req.id].permission |= req.lock;
      });
    } else {
      feature.requirements?.forEach(req => {
        this.unlockFeature(req.id, feature.id);
      });
    }
  }

  checkPermission(feature: Feature, column: 1 | 2 | 4 | 8): void {
    if ((this.helperMap[feature.id].permission | column) === this.helperMap[feature.id].permission) {
      this.helperMap[feature.id].permission -= column;
      feature.requirements?.forEach(req => {
        if ((req.value | column) === req.value) {
          this.unlockFeature(req.id, feature.id);
        }
      });
    } else {
      this.helperMap[feature.id].permission += column | 4;
      feature.requirements?.forEach(req => {
        if ((req.value | column) === req.value) {
          this.lockFeature(req.id, req.lock, feature.id);
          this.helperMap[req.id].permission |= req.lock;
        }
      });
    }
  }

  lockFeature(id: number, value: number, source: number): void {
    if (!this.featureLockDetail[id]) {
      this.featureLockDetail[id] = {
        value,
        source: [
          {
            id: source,
            value,
          },
        ],
      };
    } else {
      this.featureLockDetail[id].value &= value;
      this.featureLockDetail[id].source.push({
        id: source,
        value,
      });
    }
    this.featureLock[id] = getCrud(this.featureLockDetail[id].value);
  }

  unlockFeature(id: number, source: number): void {
    if (this.featureLockDetail[id]) {
      this.featureLockDetail[id].source = this.featureLockDetail[id].source.filter(s => s.id !== source);
      this.featureLockDetail[id].value = this.featureLockDetail[id].source.reduce((p, c) => p | c.value, 0);
      this.featureLock[id] = getCrud(this.featureLockDetail[id].value);
      if (this.featureLockDetail[id].value === 0) {
        delete this.featureLockDetail[id];
        delete this.featureLock[id];
      }
    }
  }
}
