import { OVEntity, OVField, OVForm, OVTable } from '@ov-suite/ov-metadata';
import { User } from '@ov-suite/models-idm';
import { Customer, CustomerGroup, Domain } from '@ov-suite/models-admin';
import { SalesRepModel } from '@ov-suite/models-order';

@OVEntity({ name: 'SalesRepGroup' })
@OVTable<SalesRepGroupModel>([
  {
    key: 'id',
    title: 'ID',
    type: 'number',
  },
  {
    key: 'name',
    title: 'Login',
    type: 'deep-string',
  }
])
@OVForm([
  ['name', ''],
  ['salesReps'],
  ['customers'],
  ['customerGroups'],
  ['weeklyTarget', 'monthlyTarget'],
  ['quarterlyTarget', 'yearlyTarget'],
  ['domains', ''],
])
export class SalesRepGroupModel {
  @OVField({
    type: 'number',
    generated: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Name',
    required: true,
  })
  name: string;

  @OVField({
    type: () => [SalesRepModel],
    selectionType: 'multiple',
    title: 'Sales Reps',
    flat: true,
    required: false,
    nameColumnKey: 'user.email',
    nameColumnOverride: (sr: SalesRepModel) => `${sr.user.firstName} ${sr.user.lastName}`,
    keys: ['id', 'user.firstName', 'user.lastName'],
  })
  salesReps: SalesRepModel[];

  @OVField({
    type: () => [Customer],
    tooltip: 'Assign Customers',
    selectionType: 'multiple',
    title: 'Customers',
    flat: true,
    required: false,
    keys: ['id', 'name'],
  })
  customers: Customer[];

  @OVField({
    type: () => [CustomerGroup],
    tooltip: 'Assign Customer Groups',
    selectionType: 'multiple',
    title: 'Customer Groups',
    flat: true,
    required: false,
    keys: ['id', 'name'],
  })
  customerGroups: CustomerGroup[];

  @OVField({
    type: 'number',
    title: 'Weekly Target',
  })
  weeklyTarget: number;

  @OVField({
    type: 'number',
    title: 'Monthly Target',
  })
  monthlyTarget: number;

  @OVField({
    type: 'number',
    title: 'Quarterly Target',
  })
  quarterlyTarget: number;

  @OVField({
    type: 'number',
    title: 'Yearly Target',
  })
  yearlyTarget: number;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: false,
    readonly: true,
    generated: true,
  })
  domains: Domain[];
}
