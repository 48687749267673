export class Quantities {
  low: number;

  current: number;

  high: number;

  valid: boolean;

  constructor(low: number, current: number, high: number, valid: boolean) {
    this.low = low;
    this.current = current;
    this.high = high;
    this.valid = valid;
  }
}
