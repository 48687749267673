export function variableStringInterpolation(data: string, variables: Record<string, unknown>): string {
  let output = data;
  const hasDelimiter = data.includes('${');
  if (!hasDelimiter || !variables) {
    return data;
  }

  const regex = /\${.*?}/gm;

  data.match(regex).forEach(pattern => {
    const value = getVariable(pattern.slice(2, pattern.length - 1), variables);
    if (value) {
      output = output.replace(pattern, String(value));
    }
  });

  return output;
}

function getVariable(value: string, variables: Record<string, unknown>): string {
  const [first, ...remaining] = value.split('.');

  if (remaining.length) {
    if (typeof variables[first] === 'object') {
      return getVariable(remaining.join('.'), variables[first] as Record<string, unknown>);
    }
    if (typeof variables[first] === 'string') {
      try {
        const obj = JSON.parse(variables[first] as string);
        return getVariable(remaining.join('.'), obj as Record<string, unknown>);
      } catch (e) {
        return null;
      }
    }
    return null;
  } else {
    return variables[first] ? `${variables[first]}` : null;
  }
}
