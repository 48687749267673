type BaseRecurrence = BaseRecurrenceNever | BaseRecurrenceOn | BaseRecurrenceAfter;

export enum WeekDay {
  sunday = 1,
  monday = 2,
  tuesday = 3,
  wednesday = 4,
  thursday = 5,
  friday = 6,
  saturday = 7,
}

interface BaseRecurrenceNever {
  endType: 'never';
}

interface BaseRecurrenceOn {
  endType: 'on';
  endDate: Date;
}

interface BaseRecurrenceAfter {
  endType: 'after';
  endCount: number;
}

interface DailyRecurrence {
  type: 'daily';
}

interface WeeklyRecurrence {
  type: 'weekly';
  days: number;
}

interface MonthlyRecurrence {
  type: 'monthly';
  day: number;
  weekNumber?: number;
}

interface YearlyRecurrence {
  type: 'yearly';
}

interface DefaultRecurrence {
  repeat: number;
}

export type Recurrence = (DailyRecurrence | WeeklyRecurrence | MonthlyRecurrence | YearlyRecurrence) & DefaultRecurrence & BaseRecurrence;

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DayMapper: Record<number, string> = {
  64: 'Sun',
  32: 'Mon',
  16: 'Tue',
  8: 'Wed',
  4: 'Thu',
  2: 'Fri',
  1: 'Sat',
};

export const getNumberWithOrdinal = (n: number): string => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};
