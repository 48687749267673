/* eslint-disable @typescript-eslint/naming-convention */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from './unauthorized.component';

export const UnauthorizedRoutes: Routes = [
  {
    path: '',
    component: UnauthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(UnauthorizedRoutes)],
  declarations: [UnauthorizedComponent],
})
export class UnauthorizedModule {}
