import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Domain } from '@ov-suite/models-admin';
import { OvAutoService } from '@ov-suite/services';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';
import { DomainService } from '@ov-suite/helpers-angular';
import { FieldMetadata, FieldParamsConstructor, getFieldMetadata, getSearchableMetadata, SearchableMetadata } from '@ov-suite/ov-metadata';
import { WithQuantity } from '../tree-select/tree-select.component';

@Component({
  selector: 'ov-suite-domain-selector',
  templateUrl: './domain-selector.component.html',
  styleUrls: ['./domain-selector.component.scss'],
  providers: [MakeProvider(DomainSelectorComponent)],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DomainSelectorComponent extends AbstractValueAccessor<Domain[]> implements OnInit {
  // inputs:
  @Input() flat = false;
  @Input() danger = false;
  @Input() single = false;

  hasAll = false;

  _subItem: FieldParamsConstructor;
  filteredPath: number[] = [];
  _currentData: Domain[] = [];

  metadata: FieldMetadata<Domain>;
  searchableMetadata: SearchableMetadata;

  name: string;

  currentParentId: number | string = null;
  searchValue: string;

  selectionMap: {
    [key: number]: 'selected' | 'not' | 'partial' | 'unavailable';
  } = {};

  set currentData(data: Domain[]) {
    this._currentData = data;
    this.calculateSelection();
  }

  get currentData() {
    return this._currentData;
  }

  @Input() set subItem(subItem: FieldParamsConstructor) {
    this._subItem = subItem;
  }

  @Input() ovAutoService: OvAutoService;

  @ViewChild('content') content;

  closeResult: string;

  @Input() selectedDomains: Domain[] = [];

  get multiValue() {
    if (Array.isArray(this.val)) {
      return this.val;
    }
    if (!this.val) {
      return [];
    }
    return [this.val];
  }

  formClass = Domain;

  constructor(
    @Inject('DEFAULT_API') private readonly defaultApi: string,
    private readonly modalService: NgbModal,
    private domainService: DomainService,
  ) {
    super();
  }

  ngOnInit() {
    this.metadata = getFieldMetadata(this.formClass);
    this.name = this.metadata.name;

    if (this.name) {
      this.searchableMetadata = getSearchableMetadata(this.formClass);
    }
    this.currentData = this.domainService.getDomains();
  }

  getDismissReason(reason: unknown): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    }
    if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    }
    return `with: ${reason}`;
  }

  open(content: unknown) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
      })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        },
      );
  }

  writeValue(value: Domain[]) {
    super.writeValue(value);
    this.calculateSelection();
  }

  valueChange(item: Domain[]) {
    this.writeValue(item);
  }

  setSelectedDomains(domains: Domain[]) {
    this.writeValue(domains);
  }

  calculateSelection() {
    this.currentData?.forEach(item => {
      this.selectionMap[item.id] = this.getSelectionType(item);
    });
  }

  getSelectionType(item: Domain): 'selected' | 'not' | 'partial' | 'unavailable' {
    if (this.flat) {
      return this.multiValue.some(entry => entry.id === item.id) ? 'selected' : 'not';
    }
    const path = item.path.slice(0, -1).split('.');
    path.pop();
    const unavailable = this.multiValue.some(entry => path.includes(entry.id.toString()));
    if (unavailable) {
      return 'unavailable';
    }
    const found = this.multiValue.filter(entry => (entry as Domain).path.includes(`${item.id}.`));
    if (found?.length) {
      if (found.some(entry => entry.id === item.id)) {
        return 'selected';
      }
      return 'partial';
    }
    return 'not';
  }

  close() {
    this.modalService.dismissAll();
  }

  getSearch(input?: number | string): Record<string, string[]> {
    return this.flat ? {} : { parentId: [input ? input.toString() : null] };
  }

  async select(item: Domain) {
    if (!this.flat && this.selectionMap[item.id] === 'not') {
      this.filteredPath = item.path
        .slice(0, -1)
        .split('.')
        .map(entry => Number(entry));
      this.searchValue = '';

      // get domains
      this.currentData = this.domainService.getById(item.id).children;
      this.currentParentId = item.id;
    }
  }

  async back() {
    if (this.flat) {
      return;
    }
    this.filteredPath.pop();
    const parentId = this.filteredPath.length ? this.filteredPath[this.filteredPath.length - 1] : undefined;
    if (parentId) {
      this.currentData = this.domainService.getById(parentId).children;
    } else {
      this.currentData = this.domainService.getParentDomains();
    }

    this.currentParentId = parentId ?? null;
  }

  async reset() {
    this.currentData = this.domainService.getParentDomains();
  }

  onSearchChange(event: Event): void {
    if ((event?.target as HTMLInputElement)?.value) {
      this.search((event.target as HTMLInputElement).value);
    } else {
      this.reset();
    }
  }

  search(text: string): void {
    this.currentData = this.domainService.getDomains(text);
  }

  async cautionAdd(event, item: Domain) {
    event?.stopPropagation();
    if (item && confirm('This will replace other items further down the tree')) {
      await this.add(event, item);
    }
  }

  async add(event, item: Domain) {
    event?.stopPropagation();
    let actualItem: Domain = item;

    if (this.single) {
      return;
    }
    this.selectedDomains ? this.selectedDomains.push(actualItem) : (this.selectedDomains = [actualItem]);
    let val: Domain[];
    if (!this.val) {
      val = [actualItem];
    } else if (this.flat) {
      val = [...this.multiValue, actualItem];
    } else {
      val = [...this.multiValue.filter(existing => !(existing as Domain).path.includes(`${actualItem.id}.`)), actualItem];
    }

    this.writeValue(val);
  }

  addAll() {
    if (this.flat) {
      let val;
      if (!this.hasAll) {
        this.selectedDomains = this.currentData;
        val = [...this.currentData];
      } else {
        this.selectedDomains = [];
        val = [];
      }
      this.writeValue(val);
    }
    this.hasAll = !this.hasAll;
  }

  remove(event, item: Domain | WithQuantity<Domain>) {
    event?.stopPropagation();

    this.selectedDomains = this.selectedDomains.filter(x => x.id != item.id);

    if (this.single) {
      this.writeValue(null);
      return;
    }
    this.writeValue(this.multiValue.filter(selected => (selected as Domain).id !== (item as Domain).id));
  }

  forceUpdate() {
    this.onChange(this.value);
  }
}
