import { Injectable } from '@angular/core';
import { Order, OrderItem, Quantities, Reason, ReturnItem, ReturnModel } from '@ov-suite/models-order';
import { ProductCategory, ProductSku } from '@ov-suite/models-admin';
import { OvAutoService } from '../ov-auto-service/ov-auto.service';

@Injectable()
export class AccordionService {
  isReturn = false;

  customerPriceListId = null;

  order: Order = null;

  oldOrder: Order = null;

  returnModel: ReturnModel = null;

  // Record of categoryId to productSku array
  productSkuMap: Record<number, ProductSku[]> = {};

  // Record of productSkuId to OrderItems
  orderItemMap: Record<number, OrderItem> = {};

  // Record of productSkuId to ReturnItem
  returnItemMap: Record<number, ReturnItem> = {};

  // Record of productSkuId to Reason
  reasonMap: Record<number, Reason> = {};

  reasons: Reason[];

  total = 0;

  subTotal = 0;

  vat = 0;

  keys = [
    'id',
    'sku',
    'integrationId',
    'name',
    'description',
    'imageUrl',
    'weight',
    'length',
    'height',
    'width',
    'barcodeType',
    'barcodeData',
    'salesUnit',
    'storageUnit',
    'packingUnit',
    'reportingUnit',
    'products',
    'threshold',
    'productSkuConfigurations',
    'inverseProductSkuConfigurations',
    'status',
    'active',
    'domainIds',
    'domains',
    'categoryId',
    'category',
    'quantityFree',
    'quantityOnHand',
    'quantityOnPurchaseOrder',
    'quantityOnSalesOrder',
    'quantityReserved',
    'quantityWIP',
    'taxRate',
    'prices',
    'prices.id',
    'prices.exclPrice',
    'prices.inclPrice',
    'prices.priceList',
    'prices.priceList.id',
    'prices.priceList.name',
    'prices.priceList.description',
    'prices.priceList.default',
    'prices.priceList.integrationId',
    'salesLimit',
    'productSkuConfigurations.id',
    'productSkuConfigurations.origin',
    'productSkuConfigurations.quantity',
    'productSkuConfigurations.productSku',
    'inverseProductSkuConfigurations.id',
    'inverseProductSkuConfigurations.quantity',
    'inverseProductSkuConfigurations.origin',
    'inverseProductSkuConfigurations.originId',
    'inverseProductSkuConfigurations.productSku',
    'inverseProductSkuConfigurations.productSkuId',
    'inverseProductSkuConfigurations.active',
    'threshold',
    'threshold.id',
    'threshold.amberTriggerValue',
    'threshold.redTriggerValue',
  ];

  constructor(private readonly ovAutoService: OvAutoService) {}

  onQuantityChange(quantities: Quantities, productSku: ProductSku): void {
    if (this.isReturn) {
      let returnItem = this.returnItemMap[productSku.id];
      if (!returnItem) {
        returnItem = new ReturnItem();
      }
      returnItem.productSkuId = productSku.id;
      returnItem.productSku = productSku;
      returnItem.price = this.getUnitPriceExcl(productSku) * quantities.current;
      returnItem.return = this.returnModel;
      returnItem.quantity = quantities.current;
      this.returnItemMap[productSku.id] = returnItem;

      if (!quantities.current) {
        delete this.returnItemMap[productSku.id];
        return;
      }
      returnItem.quantity = quantities.current;
      this.updateTotalsForReturns();
    } else {
      let orderItem = this.orderItemMap[productSku.id];
      if (!orderItem) {
        orderItem = new OrderItem();
      }
      orderItem.productSkuId = productSku.id;
      orderItem.productSku = productSku;
      orderItem.unitPriceExcl = this.getUnitPriceExcl(productSku);
      orderItem.unitPriceIncl = this.getUnitPriceIncl(productSku);
      orderItem.quantity = quantities.current;
      this.orderItemMap[productSku.id] = orderItem;

      if (!quantities.current) {
        delete this.orderItemMap[productSku.id];
        return;
      }
      orderItem.quantity = quantities.current;
      this.updateTotalsForOrders();
    }
  }

  getCurrentQuantity(productSku: ProductSku): number {
    if (this.isReturn) {
      return this.returnItemMap[productSku.id]?.quantity;
    }
    return this.orderItemMap[productSku.id]?.quantity;
  }

  async getProductSkusByCategoryId(categoryId: number): Promise<ProductSku[]> {
    return this.ovAutoService
      .list({
        entity: ProductSku,
        keys: this.keys,
        query: { categoryId: [categoryId], storageUnit: ['true'] },
      })
      .then(response => {
        this.productSkuMap[categoryId] = response.data;
        return response.data;
      });
  }

  getHeaders(): string[] {
    return this.isReturn
      ? ['PRODUCT', 'SKU ID', 'QTY', 'REASON', 'ORDER AMOUNT', 'TOTAL']
      : ['PRODUCT', 'SKU ID', 'QTY', 'AVAILABILITY', 'PRICE', 'SUB-TOTAL'];
  }

  setOrder(order: Order) {
    this.order = order;
    this.oldOrder = new Order();
    this.oldOrder.id = this.order.id;
    this.oldOrder.orderItems = this.order.orderItems;
    this.orderItemMap = {};
    order.orderItems.forEach(item => {
      let skuArray: ProductSku[] = [];
      if (this.productSkuMap[item.productSku.category.id]) {
        skuArray = this.productSkuMap[item.productSku.category.id];
      }
      skuArray.push(item.productSku);
      this.orderItemMap[item.productSku.id] = item;
      this.productSkuMap[item.productSku.category.id] = skuArray;
    });
    // Set initial totals
    this.subTotal = 0;
    Object.values(this.orderItemMap).forEach(record => {
      this.subTotal += this.getUnitPriceExcl(record.productSku) * record.quantity;
    });
    this.total = this.subTotal;
  }

  setReturn(returnModel: ReturnModel) {
    this.returnModel = returnModel;
    this.returnItemMap = {};
    returnModel.returnItems.forEach(item => {
      this.returnItemMap[item.productSku.id] = item;
      this.productSkuMap[item.productSku.category.id] = [item.productSku];
      this.reasonMap[item.productSku.id] = item.reason;
    });
    // Set initial totals
    this.subTotal = 0;
    Object.values(this.returnItemMap).forEach(record => {
      this.subTotal += this.getUnitPriceExcl(record.productSku) * record.quantity;
    });
    this.total = this.subTotal;
  }

  getUnitPriceExcl(productSku: ProductSku): number {
    return Number(productSku.prices.filter(price => price.priceList.default === true)[0]?.exclPrice || 0);
  }

  getUnitPriceIncl(productSku: ProductSku): number {
    return Number(productSku.prices.filter(price => price.priceList.default === true)[0]?.inclPrice || 0);
  }

  updateTotalsForOrders() {
    this.subTotal = 0;
    Object.values(this.productSkuMap).forEach(record => {
      record.forEach(sku => {
        Object.values(this.orderItemMap).forEach(item => {
          if (item.productSku.id === sku.id) this.subTotal += item.quantity * this.getUnitPriceExcl(sku);
        });
      });
    });
    this.total = this.subTotal;
  }

  updateTotalsForReturns() {
    this.subTotal = 0;
    Object.values(this.productSkuMap).forEach(record => {
      record.forEach(sku => {
        Object.values(this.returnItemMap).forEach(item => {
          if (item.productSku.id === sku.id) this.subTotal += item.quantity * this.getUnitPriceExcl(sku);
        });
      });
    });
    this.total = this.subTotal;
  }

  getSkuTotal(productSku: ProductSku): number {
    let skuTotal = 0;
    if (this.isReturn) {
      Object.values(this.returnItemMap).forEach(item => {
        if (item.productSku.id === productSku.id) skuTotal += item.quantity * this.getUnitPriceExcl(productSku);
      });
    } else {
      Object.values(this.orderItemMap).forEach(item => {
        if (item.productSku.id === productSku.id) skuTotal += item.quantity * this.getUnitPriceExcl(productSku);
      });
    }
    return skuTotal;
  }

  getSelectedCount(category: ProductCategory): number {
    let count = 0;
    if (this.isReturn) {
      Object.values(this.returnItemMap).forEach(returnItem => {
        if (category.parent) {
          if (returnItem.productSku.category.path.includes(`.${category.id}.`)) {
            count += returnItem.quantity;
          }
        } else if (returnItem.productSku.category.path.startsWith(`${category.id}.`)) {
          count += returnItem.quantity;
        }
      });
    } else {
      Object.values(this.orderItemMap).forEach(orderItem => {
        if (category.parent) {
          if (orderItem.productSku.category.path.includes(`.${category.id}.`)) {
            count += orderItem.quantity;
          }
        } else if (orderItem.productSku.category.path.startsWith(`${category.id}.`)) {
          count += orderItem.quantity;
        }
      });
    }
    return count;
  }

  addReason(reason: Reason, productSku: ProductSku): void {
    this.reasonMap[productSku.id] = reason;
  }

  loadReasons(): void {
    this.ovAutoService
      .list({
        entity: Reason,
        keys: ['id', 'reason'],
      })
      .then(response => {
        this.reasons = response.data;
      });
  }

  getIndicator(productSku: ProductSku) {
    let color = '#01DF85';
    if (productSku.quantityFree < productSku.threshold?.amberTriggerValue) {
      color = '#F5C36E';
    }
    if (productSku.quantityFree < productSku.threshold?.redTriggerValue) {
      color = '#FC5153';
    }
    return {
      backgroundColor: color,
    };
  }

  cleanUp() {
    this.subTotal = 0;
    this.total = 0;
    this.returnItemMap = {};
    this.orderItemMap = {};
    this.productSkuMap = {};
    this.returnModel = null;
    this.order = null;
    this.customerPriceListId = null;
    this.isReturn = false;
  }
}
