import { FieldValidator } from '@ov-suite/helpers-angular';
import { User } from '@ov-suite/models-idm';

export class PhoneValidator implements FieldValidator<User> {
  errorMessage = 'Phone number must Start with +27';

  async validate(user: User): Promise<Boolean> {
    const cognitoUser = user.cognitoUser;

    if (!cognitoUser) {
      return true;
    }
    return !!user.phoneNumber?.includes('+27');
  }

  async required(user: User): Promise<Boolean> {
    return user.cognitoUser;
  }
}
