import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';
import { Tag } from './tag.model';

@OVEntity('EquipmentTypeStatus', 'adminlink')
export class EquipmentTypeStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('EquipmentType', 'adminlink')
@OVForm([['#Equipment Type Details'], ['categoryID', 'name'], ['description'], ['packingEquipment'], ['tags']])
@OVSidebar([
  // ['imageUrl'],
  ['status'],
  ['id'],
])
@OVTable<EquipmentType>([
  {
    key: 'categoryID',
    title: 'Category ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Equipment Type Name',
    type: 'string',
  },
  {
    keys: ['packingEquipment'],
    type: 'other',
    action: data => (data.packingEquipment ? 'Yes' : 'No'),
    title: 'Packaging',
    disableFiltering: true,
  },
])
export class EquipmentType {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Equipment Type Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Category ID',
    placeholder: 'Required',
    required: true,
  })
  categoryID: string;

  @OVSearchable()
  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({
    type: () => [Tag],
    selectionType: 'multiple',
    title: 'Tags',
    required: false,
    flat: true,
  })
  tags: Tag[];

  @OVField({
    type: () => EquipmentTypeStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: EquipmentTypeStatus;

  @OVField({
    type: 'boolean',
    title: 'Packaging Equipment',
    tooltip: 'Is this type of equipment used as a medium for moving inventory around the warehouse',
  })
  packingEquipment: boolean;

  // @Field({ type: 'string', generated: true, readonly: true })
  // path: string;
}
