import { Routes } from "@angular/router";
import { NotificationsListComponent } from "./notifications-list.component";

export const NotificationsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: NotificationsListComponent,
      }
    ]
  }
]
