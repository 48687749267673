import { OVEntity, OVField, OVSearch, OVSearchable, OVTable } from '@ov-suite/ov-metadata';
import { Factory, Vehicle } from '@ov-suite/models-admin';

@OVEntity('TemplateVehicleLine', 'warehouselink')
@OVTable<TemplateVehicleLine>([
  {
    title: 'Vehicle Reg.',
    type: 'other',
    filterKey: 'vehicle.registration',
    keys: ['vehicle.registration'],
    action: item => item.vehicle?.registration,
  },
  {
    title: 'Vehicle Class',
    type: 'other',
    keys: ['vehicle.class.name'],
    action: item => item.vehicle?.class?.name,
    filterKey: 'vehicle.class.name',
  },
  {
    title: 'Dimension',
    type: 'other',
    disableFiltering: true,
    keys: ['vehicle.class.height', 'vehicle.class.width'],
    action: item => String(item.vehicle?.class?.height * item.vehicle?.class?.width * item.vehicle?.class?.length),
  },
  {
    title: 'Weight',
    type: 'other',
    filterKey: 'vehicle.class.weightLoadAllowed',
    keys: ['vehicle.class.weightLoadAllowed'],
    action: item => String(item.vehicle?.class?.weightLoadAllowed),
  },
  {
    title: 'Start Time',
    editable: true,
    type: 'time',
    key: 'startTime',
  },
  {
    title: 'End Time',
    editable: true,
    type: 'time',
    key: 'endTime',
  },
  {
    title: 'Start Location',
    type: 'dropdown',
    key: 'startLocation',
    id: 'startLocation',
    filterKey: 'startLocation.id',
    editable: true,
    displayKeys: ['name'],
  },
  {
    title: 'End Location',
    type: 'dropdown',
    key: 'endLocation',
    id: 'endLocation',
    editable: true,
    filterKey: 'endLocation.id',
    displayKeys: ['name'],
  },
  {
    title: 'Actions',
    keys: [''],
    disableFiltering: true,
    disableSorting: true,
    type: 'buttons',
    buttons: [
      {
        icon: 'fa fa-times text-white',
        classes: 'btn-primary btn-sm',
        action: (item, { columnAction = () => {} }) => columnAction(),
      },
    ],
  },
])
@OVSearch<TemplateVehicleLine>({ searchKeys: ['vehicle.name', 'vehicle.registration', 'startLocation.name', 'endLocation.name'] })
export class TemplateVehicleLine {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: () => Vehicle,
    title: 'Vehicle',
    keys: ['name', 'code', 'id', 'class.name', 'class.width', 'class.height', 'class.length', 'class.weightLoadAllowed'],
    placeholder: 'Required',
    required: false,
  })
  vehicle: Vehicle;

  @OVField({ type: 'number' })
  vehicleId?: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Start Time',
    placeholder: 'Required',
    required: false,
  })
  startTime: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Start Date',
    placeholder: 'Required',
    required: false,
  })
  endTime: string;

  @OVSearchable()
  @OVField({
    type: () => Factory,
    title: 'Start Location',
    placeholder: 'Required',
    selectionType: 'single',
    dropdown: true,
    required: false,
  })
  startLocation: Factory;

  @OVSearchable()
  @OVField({
    type: () => Factory,
    title: 'End Location',
    dropdown: true,
    selectionType: 'single',
    placeholder: 'Required',
    required: false,
  })
  endLocation: Factory;
}
