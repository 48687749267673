import { OVField, OVForm, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';
import { Domain, Inventory, InventoryLocation } from '@ov-suite/models-admin';

@OVEntity('InventoryContainerType', 'warehouselink')
export class InventoryContainerType {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  description: string;
}

@OVEntity('InventoryContainer', 'warehouselink')
@OVForm([['#Container Details'], ['name', 'description'], ['type', 'domains'], ['barcodeData', ''], ['qrPreview']])
@OVTable<InventoryContainer>([
  {
    key: 'name',
    title: 'Container Name',
    type: 'string',
  },
  {
    key: 'inventoryLocation.name',
    title: 'Location',
    type: 'deep-string',
  },
  {
    title: 'Inventory',
    type: 'other',
    action: ic =>
      ic.inventories
        .map(i => i.productSku?.name)
        .filter(i => !!i)
        .join(', '),
    disableSorting: true,
    keys: ['inventories', 'inventories.productSku', 'inventories.productSku.name'],
  },
  {
    title: 'Inventory Count',
    type: 'other',
    disableSorting: true,
    action: ic => ic.inventories?.reduce((p, c) => p + c.quantity, 0).toString(),
    keys: ['inventories', 'inventories.quantity'],
  },
  {
    title: 'Container Type',
    type: 'other',
    keys: ['type', 'type.name'],
    action: inventoryContainer => inventoryContainer.type?.name ?? 'Not Set',
    disableSorting: true,
    disableFiltering: true,
  },
])
@OVSearch<InventoryContainer>({ searchKeys: ['name', 'barcodeData', 'type.name'] })
export class InventoryContainer {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Description',
    placeholder: 'Required',
    required: false,
  })
  description: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Type',
    placeholder: 'Required',
    required: false,
  })
  barcodeType?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Data',
    placeholder: 'Required',
    required: false,
  })
  barcodeData?: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Container Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Created By',
    placeholder: 'Required',
    required: false,
  })
  createdBy?: string;

  @OVField({
    type: () => InventoryContainerType,
    selectionType: 'simple',
    flat: true,
    title: 'Type',
    required: true,
  })
  type: InventoryContainerType;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: true,
    readonly: true,
    generated: true,
  })
  domains: Domain[];

  @OVField({
    type: 'code-preview',
    unnecessary: true,
    codeKey: 'barcodeData',
  })
  qrPreview: void;

  @OVField({ type: () => InventoryLocation })
  inventoryLocation: InventoryLocation;

  @OVField({ type: () => [Inventory] })
  inventories: Inventory[];
}
