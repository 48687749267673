export enum InventoryContainerEventType {
  packingStarted = 'packingStarted',
  packingFinished = 'packingFinished',
  putAwayStarted = 'putAwayStarted',
  putAwayFinished = 'putAwayFinished',
  pickingStarted = 'pickingStarted',
  pickingFinished = 'pickingFinished',
  sinBinDropOff = 'sinBinDropOff',
  sinBinCheckIn = 'sinBinCheckIn',
  sinBinCheckOut = 'sinBinCheckOut',
  loadingStarted = 'loadingStarted',
  loadingFinished = 'loadingFinished',
}



enum ContainerEvent {
  created = 'created', // DB Trigger
  deleted = 'deleted', // DB Trigger
  disabled = 'disabled', // DB Trigger
  enabled = 'enabled', // DB Trigger
  locationChanged = 'locationChanged', // DB Trigger
  packed = 'packed', // DB Trigger
  cleared = 'cleared', // DB Trigger

  containerException = 'containerException', // Manual
}

enum InventoryEvent {
  created = 'created',
  deleted = 'deleted',
  disabled = 'disabled',
  enabled = 'enabled',
  containerChanged = 'containerChanged',
  productChanged = 'productChanged',
  exception = 'exception',

  packingStarted = 'packingStarted',
  packingFinished = 'packingFinished',
  putAwayStarted = 'putAwayStarted',
  putAwayFinished = 'putAwayFinished',
  pickingStarted = 'pickingStarted',
  pickingFinished = 'pickingFinished',
  loadingStarted = 'loadingStarted',
  loadingFinished = 'loadingFinished',
}
