import {
  OVField,
  OVForm,
  OVSidebar,
  OVTable,
  OVEntity,
  OVSearchable,
  CompiledFieldData,
  FieldParamsQuery,
  getTaggedMethod,
  OVSearch,
} from '@ov-suite/ov-metadata';
import { CustomerGroup, Domain, Transporter } from '@ov-suite/models-admin';
import { UserType } from './user-type.model';

@OVEntity('UserStatus', 'idmlink')
export class UserStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('User', 'idmlink')
@OVForm([
  ['firstName', 'lastName'],
  ['email', 'phoneNumber'],
  ['cognitoUser'],
  ['userPin', 'userPinGenBtn'],
  ['domains'],
  ['userTypes'],
  ['customerGroups'],
  ['transporters'],
])
@OVSidebar([['avatarUrl'], ['status'], ['id']])
@OVTable<User>([
  {
    key: 'id',
    title: 'User ID',
    type: 'string',
  },
  {
    key: 'firstName',
    title: 'User Name',
    type: 'string',
  },
  {
    key: 'lastName',
    title: 'User Surname',
    type: 'string',
  },
  {
    key: 'email',
    title: 'Email Address',
    type: 'string',
  },
  {
    key: 'phoneNumber',
    title: 'Cell Number',
    type: 'string',
  },
  {
    type: 'pills',
    title: 'User Types',
    limit: 5,
    action: item => item?.userTypes?.map(d => d.name) ?? [],
    keys: ['userTypes.name'],
    disableSorting: true,
    disableFiltering: true,
  },
  {
    type: 'pills',
    title: 'Domains',
    limit: 5,
    action: item => item?.domains?.map(d => d.name) ?? [],
    keys: ['domains.name'],
    disableSorting: true,
    disableFiltering: true,
  },
])
@OVSearch<User>({ searchKeys: ['firstName', 'lastName', 'userTypes.name', 'email', 'phoneNumber'] })
export class User {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Mobile',
    // required: (data, dataQuery: FieldParamsQuery) => dataQuery('cognitoUser') === true,
    placeholder: '+27000000000',
    tooltip: `Add users's cell phone number`,
    validatorInjectionKey: 'PHONE_VALIDATOR',
    // validator: (data: CompiledFieldData<string>, dataQuery: FieldParamsQuery): [boolean, string] => {
    //   const cognitoUser = dataQuery('cognitoUser') === true;
    //
    //   if (!cognitoUser) {
    //     return [true, ''];
    //   }
    //
    //   return [!!data?.value?.includes('+27'), 'Phone number must Start with +27'];
    // },
  })
  phoneNumber: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'First Name',
    required: true,
    placeholder: 'Required',
    tooltip: `Add users's first name`,
  })
  firstName: string;

  @OVField({
    type: 'string',
    title: 'Last Name',
    required: true,
    placeholder: 'Required',
    tooltip: `Add users's surname`,
  })
  lastName: string;

  @OVField({
    type: 'boolean',
    title: 'Can Login',
    placeholder: 'Required',
    tooltip: 'User that can login',
  })
  cognitoUser?: boolean = true;

  @OVField({
    type: 'string',
    title: 'User Pin',
    tooltip: `Add admin users's pin`,
    validatorInjectionKey: 'PIN_VALIDATOR',
    editable: false,
  })
  userPin: string;

  @OVField({
    type: 'button',
    title: 'Generate Pin code',
    placeholder: 'Required',
    classes: ['btn', 'btn-primary', 'mt-4', 'btn-round', 'btn-block'],
    unnecessary: true,
    bulkIgnore: true,
    action: (data: CompiledFieldData[][]) => {
      let userPin: CompiledFieldData;
      for (let i = 0; i < data.length; i += 1) {
        if (!userPin) {
          userPin = data[i].find(item => item.propertyKey === 'userPin');
        }
      }
      if (!userPin) return;
      // generate random access code
      userPin.value = Math.floor(Math.random() * 999_999)
        .toString()
        .padStart(5, '0');
    },
  })
  userPinGenBtn: string;

  @OVField({
    type: 'string',
    title: 'Email Address',
    placeholder: 'mail@example.com',
    tooltip: `Add users's email address`,
    validatorInjectionKey: 'EMAIL_VALIDATOR',
  })
  email: string;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: true,
    readonly: true,
    generated: true,
  })
  domains: Domain[];

  @OVField({
    type: () => [UserType],
    keys: ['id', 'name'],
    title: 'User Type',
    tooltip: 'Add multiple user types. This determines a users permission throughout the site.',
    selectionType: 'multiple',
    flat: true,
    required: true,
  })
  userTypes: UserType[];

  @OVField({
    type: () => [CustomerGroup],
    keys: ['id', 'name'],
    title: 'Customer Groups',
    tooltip: 'Add multiple customers where necessary.',
    selectionType: 'multiple',
    flat: true,
    required: false,
    dropdownLimit: 100000,
  })
  customerGroups: CustomerGroup[];

  @OVField({
    type: () => [Transporter],
    keys: ['id', 'name'],
    title: 'Transporters',
    tooltip: 'Add multiple Transporters where necessary.',
    selectionType: 'multiple',
    flat: true,
    required: false,
  })
  transporters: Transporter[];

  @OVField({
    type: 'image',
    sidebar: true,
    required: false,
    title: 'Image',
    tooltip: 'upload your profile picture',
  })
  avatarUrl?: string;

  @OVField({
    type: () => UserStatus,
    dropdown: true,
    sidebar: true,
  })
  status: UserStatus;
}
