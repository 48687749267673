<div class="wrapper">
  <div class="sidebar">
    <ov-suite-sidebar #sidebar [projectName]="projectName" [routes]="routes"></ov-suite-sidebar>
  </div>
  <div class="main-panel">
    <ov-suite-notification
      [open]="isNotificationOpen"
      (isClosed)="onClose($event)"
      (notificationCount)="getNotificationCount($event)"
    ></ov-suite-notification>
    <ov-suite-navbar
      [routes]="routes"
      [notifications]="notificationCount"
      (openNotifications)="openNotifications($event)"
      [isNotificationOpen]="isNotificationOpen"
    ></ov-suite-navbar>
    <router-outlet></router-outlet>
  </div>
</div>
