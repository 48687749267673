import { Component, Input } from '@angular/core';
import { ColumnDataDeepString} from '@ov-suite/helpers-shared';

@Component({
  selector: 'ov-suite-deep-string-cell',
  template: `
    <span>
      {{ getDeepValue(item.id, item, col) }}
    </span>
  `,
})
export class DeepStringCellComponent<T> {
  @Input() item: T;
  @Input() col: ColumnDataDeepString<T>;

  // @Memo()
  getDeepValue(id: number, item: T, col: ColumnDataDeepString<T>): string {
    const keys = col.key.split('.');
    const value = keys.reduce((p, c) => p?.[c], item);
    return value ? `${value}` : null;
  }
}
