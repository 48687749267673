import { OVField, OVEntity } from '@ov-suite/ov-metadata';

@OVEntity({ name: 'PriceList', tableName: 'price_list' })
export class PriceListModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  description?: string;

  @OVField({ type: 'boolean' })
  default: boolean;
}
