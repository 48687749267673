import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AccordionService, OvAutoService } from '@ov-suite/services';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { QRCodeModule } from 'angularx-qrcode';
import { TableComponent } from './table/table.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CreateOrEditComponent } from './create-or-edit/create-or-edit.component';
import { FormComponent } from './form/form.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { HierarchyBreadcrumbComponent } from './hierarchy-breadcrumb/hierarchy-breadcrumb.component';
import { InputComponent } from './input/input.component';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { HierarchyTabsComponent } from './hierarchy-tabs/hierarchy-tabs.component';
import { PermissionMatrixComponent } from './permission-matrix/permission-matrix.component';
import { UserProfileWidgetComponent } from './user-profile-widget/user-profile-widget.component';
import { AgmMapComponent } from './agm-map/agm-map.component';
import { DaySelectorComponent } from './day-selector/day-selector.component';
import { TableTopBarComponent } from './table-top-bar/table-top-bar.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PillMultiSelectComponent } from './pill-multi-select/pill-multi-select.component';
import { DateTimeModule } from './date-time/date-time.module';
import { RecurrenceComponent } from './reccurence/recurrence.component';
import { MapDeepObjectChangeDiff, MapDeepObjects } from './pipes/deep-object.pipe';
import { NormaliseString } from './pipes/text-transform.pipe';
import { CardTableComponent } from './card-table/card-table.component';
import { PopupTableComponent } from './popup-table/popup-table.component';
import { DateRangeComponent } from './date-time/date-range.component';
import { HierarchyListComponent } from './hierarchy-list/hierarchy-list.component';
import { TrudaHierarchyListComponent } from './hierarchy-list/sub-lists/truda-list/truda-hierarchy-list.component';
import { CeramicHierarchyListComponent } from './hierarchy-list/sub-lists/ceramic-list/ceramic-hierarchy-list.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { TopFiltersComponent } from './top-filters/top-filters.component';
import { UiIconsModule } from './ui-icons.module';
import { BasicTableComponent } from './new-table/basic-table/basic-table.component';
import { StringCellComponent } from './new-table/basic-table/cells/string/string-cell.component';
import { DeepStringCellComponent } from './new-table/basic-table/cells/deep-string/deep-string-cell.component';
import { NumberCellComponent } from './new-table/basic-table/cells/number/number-cell.component';
import { DateCellComponent } from './new-table/basic-table/cells/date/date-cell.component';
import { DateTimeCellComponent } from './new-table/basic-table/cells/date-time/date-time-cell.component';
import { TimeCellComponent } from './new-table/basic-table/cells/time/time-cell.component';
import { DropdownCellComponent } from './new-table/basic-table/cells/dropdown/dropdown-cell.component';
import { OtherCellComponent } from './new-table/basic-table/cells/other/other-cell.component';
import { PillsCellComponent } from './new-table/basic-table/cells/pills/pills-cell.component';
import { ButtonsCellComponent } from './new-table/basic-table/cells/buttons/buttons-cell.component';
import { StatusCellComponent } from './new-table/basic-table/cells/status/status-cell.component';
import { BooleanCellComponent } from './new-table/basic-table/cells/boolean/boolean-cell.component';
import { DomainSelectorComponent } from './domain-selector/domain-selector.component';
import { FilterTabsComponent } from './filter-tabs/filter-tabs.component';
import { PredictiveTextDropdownComponent } from './predictive-text-dropdown/predictive-text-dropdown.component';
import { IncrementPickerComponent } from './increment-picker/increment-picker.component';
import { AccordionCategoryComponent } from './accordion-category/accordion-category.component';
import { AccordionProductSkuComponent } from './accordion-product-sku/accordion-product-sku.component';

export interface ApiOptions {
  apiUrl?: string;
  imageUploadUrl?: string;
}

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule,
    DateTimeModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    LeafletModule,
    QRCodeModule,
    UiIconsModule,
  ],
  declarations: [
    AgmMapComponent,
    TableComponent,
    NavbarComponent,
    SidebarComponent,
    CreateOrEditComponent,
    FormComponent,
    BulkUploadComponent,
    PopupTableComponent,
    InputComponent,
    PredictiveTextDropdownComponent,
    TreeSelectComponent,
    HierarchyBreadcrumbComponent,
    ImageUploadComponent,
    HierarchyTabsComponent,
    PermissionMatrixComponent,
    UserProfileWidgetComponent,
    DaySelectorComponent,
    TableTopBarComponent,
    FileUploadComponent,
    PillMultiSelectComponent,
    MapDeepObjects,
    MapDeepObjectChangeDiff,
    NormaliseString,
    RecurrenceComponent,
    CardTableComponent,
    DateRangeComponent,
    CeramicHierarchyListComponent,
    HierarchyListComponent,
    TrudaHierarchyListComponent,
    AdvancedSearchComponent,
    TopFiltersComponent,
    DomainSelectorComponent,
    FilterTabsComponent,
    BasicTableComponent,
    StringCellComponent,
    DeepStringCellComponent,
    NumberCellComponent,
    DateCellComponent,
    DateTimeCellComponent,
    TimeCellComponent,
    DropdownCellComponent,
    OtherCellComponent,
    PillsCellComponent,
    ButtonsCellComponent,
    StatusCellComponent,
    BooleanCellComponent,
    IncrementPickerComponent,
    AccordionCategoryComponent,
    AccordionProductSkuComponent,
  ],
  exports: [
    AgmMapComponent,
    TableComponent,
    NavbarComponent,
    SidebarComponent,
    CreateOrEditComponent,
    FormComponent,
    BulkUploadComponent,
    InputComponent,
    PredictiveTextDropdownComponent,
    PopupTableComponent,
    ImageUploadComponent,
    HierarchyBreadcrumbComponent,
    TreeSelectComponent,
    HierarchyTabsComponent,
    PermissionMatrixComponent,
    UserProfileWidgetComponent,
    PermissionMatrixComponent,
    DaySelectorComponent,
    TableTopBarComponent,
    FileUploadComponent,
    PillMultiSelectComponent,
    MapDeepObjects,
    MapDeepObjectChangeDiff,
    NormaliseString,
    RecurrenceComponent,
    CardTableComponent,
    DateRangeComponent,
    CeramicHierarchyListComponent,
    HierarchyListComponent,
    TrudaHierarchyListComponent,
    AdvancedSearchComponent,
    TopFiltersComponent,
    BasicTableComponent,
    DomainSelectorComponent,
    FilterTabsComponent,
    IncrementPickerComponent,
    AccordionCategoryComponent,
    AccordionProductSkuComponent,
  ],
  providers: [OvAutoService, AccordionService],
})
export class UiModule {
  static forRoot(options: ApiOptions): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [{ provide: 'OPTIONS', useValue: options }],
    };
  }

  static forChild() {
    return {
      ngModule: UiModule,
    };
  }
}

export { RouteInfo, ChildrenItems } from './sidebar/sidebar.model';
export { NavbarComponent } from './navbar/navbar.component';

export * from './form/form.component';
export * from './hierarchy-tabs/hierarchy-tabs.model';
