import { Constructor, ConstructorReturn, getFieldMetadata, getTypeMetadata } from '@ov-suite/ov-metadata';
import { AddressModel } from '../address.model';

export function mapToClass<T = unknown, U = unknown>(constructor: Constructor<T>, input: U): T {
  const metadata = getFieldMetadata(constructor);
  const output = new constructor();
  for (const field of metadata.fields) {
    let value = input[field.propertyKey];
    if (field.type === 'json' && value && typeof value === 'string') {
      value = JSON.parse(value);
    }
    if (field.type === 'map' && !!value) {
      const address = new AddressModel();
      address.line1 = value.line1;
      address.line2 = value.line2;
      address.line3 = value.line3;
      address.line4 = value.line4;
      address.line5 = value.line5;
      address.line6 = value.line6;
      address.postalCode = value.postalCode;
      if (typeof value.geography === 'string') {
        try {
          address.geography = JSON.parse(value.geography);
        } catch (e) {
          // Ignore Error
        }
      }
      value = address;
    }
    if (typeof field.type !== 'string' && value) {
      const type = getTypeMetadata(field.type as ConstructorReturn);
      if (Array.isArray(value)) {
        value = value.map(i => mapToClass(type.entity, i));
      } else {
        value = mapToClass(type.entity, value);
      }
    }
    output[field.propertyKey] = value;
  }
  return output;
}
