<div class="col-12 date-input">
  <small class="text-danger" *ngIf="showTime && (!time || !date)">Please Set a Time and Date</small>
  <div class="row">
    <div [ngClass]="showTime ? 'col-7' : 'ol-12'">
      <mat-form-field>
        <input
          matInput
          class="form-control"
          [(ngModel)]="date"
          (ngModelChange)="valueChange()"
          [matDatepicker]="datePicker"
          [disabled]="disabled"
          [ngModelOptions]="{ standalone: true }"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-4 pl-1 pr-0" *ngIf="showTime">
      <mat-form-field>
        <input
          matInput
          name="dueDateTime"
          [disabled]="disabled"
          type="time"
          [(ngModel)]="time"
          [value]="time"
          (ngModelChange)="valueChange()"
          [ngModelOptions]="{ standalone: true }"
          required
        />
      </mat-form-field>
      <div class="date-check-mark" *ngIf="showTime">
        <i class="fa fa-check text-success" *ngIf="!!time && !!date" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</div>
