import gql from 'graphql-tag';

export function checkStartOfDay() {
  return gql(`
    query CheckStartOfDay {
      checkStartOfDay {
        completed
      }
    }
  `);
}
