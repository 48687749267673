import { OVEntity, OVField, OVSearch, OVSearchable, OVTable } from '@ov-suite/ov-metadata';
import { Customer, Domain, Factory } from '@ov-suite/models-admin';

@OVEntity('MasterRoute')
@OVTable<MasterRoute>([
  {
    key: 'id',
    title: 'ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    key: 'createdDate',
    title: 'Date Created',
    type: 'date',
    format: 'DD MMM yyyy',
  },
  {
    type: 'pills',
    limit: 5,
    title: 'Customers',
    action: t => t.customers?.map(i => i.name) ?? [],
    keys: ['customers.name'],
    disableSorting: true,
    disableFiltering: true,
  },
  {
    type: 'deep-string',
    title: 'Source',
    key: 'source.name',
    disableFiltering: true,
  },
  {
    type: 'deep-string',
    title: 'Destination',
    key: 'destination.name',
    disableFiltering: true,
  },
])
@OVSearch<MasterRoute>({ searchKeys: ['name', 'destination.name', 'source.name', 'customers.name'] })
export class MasterRoute {
  @OVField({ type: 'number' })
  id: number;

  @OVSearchable()
  @OVField({ type: 'string' })
  name: string;

  @OVField({
    type: () => [Customer],
    keys: ['id', 'name'],
  })
  customers: Customer[];

  @OVField({ type: 'numbers' })
  customerIds: number[];

  @OVField({ type: () => Factory })
  source: Factory;

  @OVField({ type: 'number' })
  sourceId: number;

  @OVField({ type: () => Factory })
  destination: Factory;

  @OVField({ type: 'number' })
  destinationId: number;

  @OVField({ type: 'date' })
  createdDate: Date;

  @OVField({
    type: () => [Domain],
    selectionType: 'multiple',
    title: 'Domains',
    required: true,
  })
  domains: Domain[];
}
