import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';
import { FactoryArea } from './factory-area.model';
import { Factory } from './factory.model';
import { ProductSku } from './product-sku.model';

@OVEntity('InventoryLocationCategory')
export class InventoryLocationCategory {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  description: string;
}

@OVEntity('InventoryLocationStatus')
export class InventoryLocationStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('InventoryLocation')
@OVForm([
  ['#Inventory Location Details'],
  ['name', 'description'],
  ['capacity', 'factory'],
  ['factoryArea'],
  ['productSkus'],
  ['category', 'isStorage'],
  ['barcodeData', ''],
  ['inputPriority', 'outputPriority'],
  ['qrPreview'],
])
@OVSidebar([['status'], ['id']])
@OVTable<InventoryLocation>([
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    key: 'description',
    title: 'Description',
    type: 'string',
  },
  {
    type: 'other',
    title: 'Number of Sub Storage',
    action: item => item?.childrenCount?.toString() ?? '0',
    routerLink: () => [],
    keys: ['childrenCount'],
    queryParams: (item: { id: number }) => ({ _parentId: item.id }),
    disableSorting: true,
    disableFiltering: true,
  },
])
@OVSearch<InventoryLocation>({
  searchKeys: ['name', 'productSkus.sku', 'productSkus.name', 'factory.factoryCode', 'factory.name', 'factory.address'],
})
export class InventoryLocation {
  @OVField({
    type: 'number',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: () => InventoryLocationStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: InventoryLocationStatus;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'Description',
    required: false,
  })
  description?: string;

  @OVField({
    type: 'boolean',
    title: 'IsStorage',
    required: false,
  })
  isStorage?: boolean;

  @OVSearchable()
  @OVField({
    type: () => FactoryArea,
    keys: ['id', 'name'],
    selectionType: 'single',
    title: 'Factory Area',
    tooltip: 'Factory Area',
    placeholder: 'Required',
    required: false,
  })
  factoryArea: FactoryArea;

  @OVField({
    type: () => InventoryLocationCategory,
    selectionType: 'simple',
    title: 'Category',
    required: false,
  })
  category: InventoryLocationCategory;

  @OVField({
    type: () => [ProductSku],
    keys: ['id', 'name'],
    selectionType: 'multiple',
    title: 'Product SKUs',
    tooltip: 'Packing Units',
    flat: true,
    required: false,
    query: {
      packingUnit: [{ operator: '=', value: true }],
    },
  })
  productSkus: ProductSku;

  @OVSearchable()
  @OVField({
    type: () => Factory,
    keys: ['id', 'name'],
    title: 'Factory',
    tooltip: 'Factory',
    selectionType: 'simple',
    placeholder: 'Required',
    required: true,
  })
  factory: Factory;

  @OVField({
    type: () => InventoryLocation,
    title: 'Parent',
    bulkDependency: 'id',
  })
  parent?: InventoryLocation;

  @OVField({ type: () => InventoryLocation })
  children?: InventoryLocation[];

  @OVField({
    type: 'number',
    generated: true,
    readonly: true,
  })
  childrenCount?: number;

  @OVField({
    type: 'number',
    title: 'Capacity',
    required: false,
  })
  capacity: number;

  @OVField({ type: 'string', generated: true, readonly: true })
  path: string;

  @OVField({
    type: 'string',
    title: 'Barcode Type',
  })
  barcodeType: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Value',
  })
  barcodeData: string;

  @OVField({
    type: 'code-preview',
    unnecessary: true,
    codeKey: 'barcodeData',
  })
  qrPreview: void;

  @OVField({
    type: 'number',
    title: 'Input Priority',
    required: false,
  })
  inputPriority = 1;

  @OVField({
    type: 'number',
    title: 'Output Priority',
    required: false,
  })
  outputPriority = 1;
}
