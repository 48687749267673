<div class="hierarchy-table">
  <ng-container *ngIf="data.length > 0 || !emptyComponent || filterHistory; else emptyComponent">
    <div class="hierarchy-table-card card slide-in-right">
      <div class="row d-flex justify-content-between pr-5" *ngIf="!showTopBar">
        <div class="col-md-4 ht-header">
          <h5>{{ title }}</h5>
        </div>
        <div class="col-md-8 row hierarchy-input-container">
          <div class="hierarchy-input">
            <ov-suite-advanced-search
              [filterEnabled]="true"
              [metadata]="metadata"
              (filterChange)="onAdvancedFilterChange($event)"
              (searchChange)="onSearchChange($event)"
              [dropdownData]="dropdownData"
            >
            </ov-suite-advanced-search>
          </div>
          <div class="hierarchy-input">
            <button class="btn btn-dark btn-block btn-round btn-custom" (click)="customize()">
              {{ customizing ? 'Done' : 'Customize'
              }}<span *ngIf="!customizing" class="float-right mr-2"><i aria-hidden="true" class="fa fa-pencil-square-o"></i></span
              ><span *ngIf="customizing" class="float-right mr-2"><i aria-hidden="true" class="fa fa-check"></i></span>
            </button>
          </div>
          <div *ngIf="hasBulkUpload" class="hierarchy-input">
            <ov-suite-bulk-upload
              class="ov-suite-bulk-upload"
              *ngIf="(hasPermissionsSet && hasCreatePermission) || !hasPermissionsSet"
              [metadata]="metadata"
              [filterQuery]="filter"
              (hasUploaded)="uploaded($event)"
            ></ov-suite-bulk-upload>
          </div>
          <div *ngIf="!hideAddButton" class="hierarchy-input">
            <button
              *ngIf="(hasPermissionsSet && hasCreatePermission) || !hasPermissionsSet"
              class="btn btn-dark btn-block btn-round"
              (click)="add()"
            >
              Add<span class="float-right mr-2"><i aria-hidden="true" class="fa fa-plus"></i></span>
            </button>
          </div>
          <div *ngIf="bulkActionComponent" class="hierarchy-bulk-action-input">
            <div>
              <div ngbDropdown display="dynamic" placement="left-top" class="d-inline-block">
                <button class="btn btn-outline-primary bulk-action-btn" id="bulkActionDropdown" ngbDropdownToggle>
                  Bulk Actions &nbsp; &nbsp;
                </button>
                <div ngbDropdownMenu aria-labelledby="bulkActionDropdown">
                  <ng-container *ngTemplateOutlet="bulkActionComponent"> </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="showTopBar">
        <ov-suite-table-top-bar
          [barTitle]="title"
          [metadata]="metadata"
          [constructorName]="formClass?.name"
          [customizing]="customizing"
          (search)="onSearchChange($event)"
          (customize)="customize()"
          [filterQuery]="filter"
          [hideBulkUpload]="hasBulkUpload"
          (hasUploaded)="uploaded($event)"
        ></ov-suite-table-top-bar>
      </div>
      <div class="card-body" [ngClass]="{ 'pt-1 table-hover': showTopBar, 'show-scrollbar': true }">
        <ov-suite-table
          [showIndex]="false"
          [striped]="!showTopBar"
          [selectableRows]="selectableRows"
          [showFiller]="showFiller"
          [data]="data"
          [loading]="loading"
          [columnData]="metadata?.table"
          [totalCount]="totalCount"
          [pageSize]="pageSize"
          [currentPage]="page"
          (changePage)="changePage($event)"
          (itemSelect)="onItemSelected($event)"
          [select]="select"
          [back]="back"
          [hideColumnKeys]="hideColumnKeys"
          [keyStore]="metadata?.name"
          (filterChange)="filterChange($event)"
          (orderChange)="orderChange($event)"
          [columnHider]="customizing"
          [editableRows]="editableRows"
          (itemEdited)="onItemEdit($event)"
          [dropdownData]="dropdownData"
          (changePageSize)="pageSizeInput($event)"
          [filterEnabled]="filterEnabled"
          [showPageSelect]="showPageSelect"
          [pageChangeEnabled]="pageChangeEnabled"
        ></ov-suite-table>
      </div>
    </div>
  </ng-container>

  <div *ngIf="showTopBar && !hideAddButton">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <button
          *ngIf="(hasPermissionsSet && hasCreatePermission) || !hasPermissionsSet"
          class="btn btn-dark w-25 float-right"
          (click)="add()"
        >
          Add<span class="float-right mr-2"><i aria-hidden="true" class="fa fa-plus"></i></span>
        </button>
      </div>
    </div>
  </div>
</div>
