import { NgModule } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { Observable, Subscription } from 'rxjs';
import { SseClientService } from './sse-client.service';
import { refreshSession } from '../cognito.helper';

@NgModule({})
export class SseModule {
  idmEvents: Observable<string | object>;

  adminEvents: Observable<string | object>;

  idmSub: Subscription;

  adminSub: Subscription;

  constructor(private readonly sseService: SseClientService) {
    this.setupSse();

    Hub.listen('auth', () => {
      this.setupSubscriptions();
    });
  }

  setupSubscriptions() {
    this.adminSub?.unsubscribe();
    this.idmSub?.unsubscribe();

    Auth.currentUserInfo().then(info => {
      if (info) {
        const { attributes } = info;
        this.adminSub = this.adminEvents?.subscribe(event => {
          if (typeof event === 'object') {
            if (event['entity'] === 'domain') {
              refreshSession();
            }
          }
        });
        this.idmSub = this.idmEvents?.subscribe(event => {
          if (typeof event === 'object') {
            if (event['entity'] === 'user') {
              if (event['sub'] === attributes.sub) {
                refreshSession();
              }
            } else if (event['entity'] === 'userType') {
              refreshSession();
            }
          }
        });
      }
    });
  }

  setupSse() {
    // this.idmEvents = this.sseService.getServerSentEvent(`${environment.apiUrl}/api/events`);
    // this.adminEvents = this.sseService.getServerSentEvent(`${environment.apiUrl}/api/events`);
  }
}
