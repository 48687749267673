import { CompiledFieldData, OVEntity, OVField, OVForm, OVSearchable, OVTable } from '@ov-suite/ov-metadata';
import { Vehicle } from '@ov-suite/models-admin';
import { VehicleExceptionReason } from './vehicle-exception-reason.model';

@OVEntity('VehicleException', 'warehouselink', 'VehicleExceptions')
@OVForm([[''], ['vehicles', ''], ['startDate', 'startTime'], ['endDate', 'endTime'], ['reason', 'otherReason']])
@OVTable<VehicleException>([
  {
    title: 'Vehicle Class',
    type: 'other',
    filterKey: 'vehicles.class.name',
    keys: ['vehicles.class.name'],
    action: item => {
      const [vehicle] = item.vehicles;
      return vehicle?.class?.name;
    },
  },
  {
    title: 'Vehicle Reg',
    type: 'other',
    filterKey: 'vehicles.registration',
    disableSorting: false,
    keys: ['vehicles.registration'],
    action: item => {
      const [vehicle] = item.vehicles;
      return vehicle?.registration;
    },
  },
  {
    key: 'startDate',
    title: 'Start Date',
    type: 'date',
  },
  {
    key: 'startTime',
    title: 'Start Time',
    type: 'time',
  },
  {
    key: 'endDate',
    title: 'End Date',
    type: 'date',
  },
  {
    key: 'endTime',
    title: 'End Time',
    type: 'time',
  },
  {
    key: 'reason.name',
    title: 'Reason',
    type: 'deep-string',
    disableFiltering: true,
  },
  {
    key: 'otherReason',
    title: 'Reason Detail',
    type: 'string',
  },
])
export class VehicleException {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: () => [Vehicle],
    title: 'Select Vehicle',
    keys: ['registration', 'id', 'code', 'class.name'],
    flat: true,
    dropdownKeys: ['id', 'name'],
    nameColumnLabel: 'Registration',
    nameColumnKey: 'registration',
    placeholder: 'Required',
    dropdown: true,
    selectionType: 'multiple',
    required: true,
  })
  vehicles: Vehicle[];

  @OVField({
    type: 'date',
    title: 'Start Date',
    placeholder: 'Required',
    required: false,
  })
  startDate: string;

  @OVField({
    type: 'date',
    title: 'End Date',
    placeholder: 'Required',
    required: false,
  })
  endDate: string;

  @OVField({
    type: 'time',
    title: 'Start Time',
    placeholder: 'Required',
    required: false,
  })
  startTime: string;

  @OVField({
    type: 'time',
    title: 'End Time',
    placeholder: 'Required',
    required: false,
  })
  endTime: string;

  @OVField({
    type: () => VehicleExceptionReason,
    title: 'Reason',
    selectionType: 'simple',
    keys: ['id', 'name'],
    placeholder: '',
    classes: ['d-none', 'invisible'],
    required: true,
  })
  reason: VehicleExceptionReason;

  @OVField({
    type: 'string',
    title: 'Other Reason',
    placeholder: '',
    hidden: (data: CompiledFieldData[]) => {
      const dataValue = data.find(item => item['propertyKey'] === 'reason');
      if (!dataValue.value) {
        return true;
      }
      return dataValue.value && dataValue.value['id'] !== 1; // 1 = Other, so any value other than Other
    },
    required: false,
  })
  otherReason: string;
}
