import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';
import { MapLocation } from '@ov-suite/models-helper';
import { AddressModel } from '@ov-suite/models-shared';
import { Area } from './area.model';
import { Domain } from './domain.model';

@OVEntity('FactoryStatus', 'shared')
export class FactoryStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity({ name: 'Factory', api: 'shared' })
@OVForm([['#Factory Details'], ['factoryCode', 'name'], ['description'], ['domains'], ['area'], ['address']])
@OVForm([['#Factory Details'], ['factoryCode', 'name'], ['description'], ['domains']])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<Factory>([
  {
    key: 'factoryCode',
    title: 'Factory ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Factory Name',
    type: 'string',
  },
])
@OVSearch<Factory>({ searchKeys: ['factoryCode', 'name', 'area.areaId', 'area.name', 'address'] })
export class Factory {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Factory Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Factory Code',
    tooltip: 'Used as a unique key for identification throughout the site.',
    placeholder: 'Required',
    required: true,
  })
  factoryCode: string;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({
    type: 'image',
    sidebar: true,
    required: false,
    title: 'Image',
  })
  imageUrl?: string;

  @OVField({
    type: () => FactoryStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: FactoryStatus;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: true,
    readonly: true,
    generated: true,
  })
  domains: Domain[];

  @OVField({
    type: () => Area,
    selectionType: 'single',
    title: 'Area',
    tooltip: 'Add a single area for this factory.',
    required: true,
  })
  area?: Area;

  // Todo: Fix Factory
  // @OVField({
  //   type: 'map',
  //   required: false,
  //   // coordinatesKey: 'coordinates',
  //   // addressKey: 'address',
  // })
  // map?: MapLocation;

  @OVField({ type: 'map' })
  address: AddressModel;
}
