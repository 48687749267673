import { OVField, OVSearchable, OVEntity, OVTable } from '@ov-suite/ov-metadata';
import { InventoryContainerEventType } from '@ov-suite/helpers-shared/lib/inventory-container-event-type';
import { InventoryContainer } from './inventory-container.model';

function humanizeString(input: string): string {
  let output = '';
  input.split('').forEach(letter => {
    let char = letter;
    if (!output) char = letter.toUpperCase();
    else if (letter === letter.toUpperCase()) char = ` ${letter.toUpperCase()}`;
    else if (output.endsWith(' ')) char = letter.toUpperCase();
    output += char;
  });
  return output;
}

@OVEntity('InventoryContainerEvent', 'warehouselink')
@OVTable<InventoryContainerEvent>([
  {
    key: 'container.name',
    title: 'Container Name',
    type: 'deep-string',
  },
  {
    title: 'Action',
    action: i => humanizeString(i.action),
    type: 'other',
    keys: ['action'],
  },
  {
    key: 'actionedBy',
    title: 'Actioned By',
    type: 'string',
  },
  {
    title: 'Timestamp',
    type: 'date',
    format: 'DD/MM/YYYY hh:mm:ss A',
    key: 'timestamp',
    filterKey: 'entryTime',
  },
])
export class InventoryContainerEvent {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: false,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Action',
    tooltip: 'Action',
    placeholder: '',
    required: true,
  })
  action: InventoryContainerEventType;

  @OVField({
    type: 'string',
    title: 'Description',
    tooltip: 'Description',
    placeholder: '',
    required: true,
  })
  description: string;

  @OVField({
    type: () => InventoryContainer,
    title: 'Pallet',
    selectionType: 'simple',
    keys: ['id', 'name'],
    placeholder: '',
    required: false,
  })
  container?: InventoryContainer;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Actioned By',
    placeholder: '',
    required: true,
  })
  actionedBy: string;

  @OVField({
    type: 'date-time',
    title: 'Timestamp',
    placeholder: '',
    required: false,
  })
  timestamp: string;
}
