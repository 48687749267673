type App = 'idm' | 'admin' | 'warehouse' | 'yard' | 'execution' | 'ceramicPortal' | 'order';

interface CognitoEnvironment {
  region: string;
  identityPoolId: string;
  userPoolId: string;
  clientId: string;
  bucketId: string;
  oauth: {
    domain?: string;
    scope?: string[];
    redirectSignIn?: string;
    redirectSignOut?: string;
    responseType?: string;
  };
  federatedButtonText?: string;
  passwordPolicyText?: string;
}

type WebUrlEnvironment = Record<App, string>;
type AppIdEnvironment = Record<App, number>;

interface OtherEnvironment {
  mapApiKey: string;
  awsFileServiceApi: string;
}

interface InhanceWorkerEnvironment {
  authUrl: string;
  appClientId: string;
  appClientSecret: string;
  apiUrl: string;
}

interface RedisEnvironment {
  host?: string;
  port?: number;
  db?: number;
  password?: string;
}

export interface Environment {
  production: boolean;
  envName: string;

  cognito: CognitoEnvironment;

  domain: string;
  apiUrl: string;
  webUrl: WebUrlEnvironment;

  appId: AppIdEnvironment;

  other: OtherEnvironment;

  inhanceWorker?: InhanceWorkerEnvironment;

  redis?: RedisEnvironment;
}

class Env implements Environment {
  production = false;
  envName = 'development';

  cognito: CognitoEnvironment = {
    region: 'eu-central-1',
    clientId: '1iuorn2s7t84iaeeds5d6hnkkh',
    identityPoolId: 'eu-central-1:3a3ebdc4-c8b7-4aac-839f-2336f4c43578',
    userPoolId: 'eu-central-1_lH5BYgGC6',
    bucketId: 'ovsuitebucketdevelop-develop',
    oauth: {},
    passwordPolicyText: 'Password must contain at least 8 characters, 1 upper case, 1 lower case character and a number.',
  };

  domain = 'localhost';
  apiUrl = 'http://localhost:3300';

  webUrl: WebUrlEnvironment = {
    idm: 'http://localhost:4201',
    admin: 'http://localhost:4202',
    warehouse: 'http://localhost:4203',
    yard: 'http://localhost:4204',
    execution: 'http://localhost:4205',
    ceramicPortal: 'http://localhost:4206',
    order: 'http://localhost:4207',
  };

  appId: AppIdEnvironment = {
    idm: 8,
    admin: 9,
    warehouse: 1,
    yard: 6,
    execution: 5,
    ceramicPortal: 7,
    order: 3,
  };

  other: OtherEnvironment = {
    mapApiKey: `AIzaSyAd5CAIBIGzFYxj8SU1i1VN3pTXf3O8Ugs`,
    awsFileServiceApi: `https://uun21udnjd.execute-api.eu-central-1.amazonaws.com/dev`,
  };

  inhanceWorker?: InhanceWorkerEnvironment;

  redis?: RedisEnvironment;

  updateValues(env: Environment) {
    if (env) {
      localStorage.setItem('config', JSON.stringify(env));
    }
    const fields: (keyof Environment)[] = [
      'production',
      'envName',
      'cognito',
      'apiUrl',
      'webUrl',
      'appId',
      'other',
      'inhanceWorker',
      'redis',
    ];
    fields.forEach((key: keyof Environment) => {
      if (env[key]) {
        // @ts-ignore
        this[key] = env[key];
      }
    });
  }
}

const d = new Env();
export const setEnvironment = (env: Environment) => {
  d.updateValues(env);
};
export const environment = d;
