import {
  Component,
  EventEmitter, Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import Auth from '@aws-amplify/auth';
import { Notification } from "@ov-suite/models-shared";
import { OvAutoService } from "@ov-suite/services";
import moment from "moment";
import { environment } from "@ov-suite/helpers-shared";
import { Apollo } from "apollo-angular";
import gql from 'graphql-tag';

@Component({
  selector: 'ov-suite-notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notifications: Notification[] = [];
  isOpen;
  @Output() notificationCount = new EventEmitter();
  @Output() isClosed = new EventEmitter();

  @Input() set open(open) {
    if(!open && this.notifications.length > 0){
      this.markVisibleAllAsRead();
    }
    this.isOpen = open;
  }

  @Input()
  set notificationsEvent(event) {
    this.notifications = event;
  }

  constructor(
    private readonly router: Router,
    private readonly ovAutoService: OvAutoService,
    private apollo: Apollo,
    @Inject('DEFAULT_API') private readonly defaultApi: string,
  ) {}

  ngOnInit(): void {
    this.getNotifications();
    this.startNotificationLoader();
  }

  startNotificationLoader() {
    setInterval(() => {
      this.getNotifications();
    }, 20000);
  }

  getNotifications(){
    this.ovAutoService.list({
      entity: Notification,
      limit: 7
    }).then(res => {
      this.notifications = res.data;
      this.notificationCount.emit(res.totalCount);
    });
  }

  // clearNotification(notify: Notification) {
  //     notify.readDate = new Date().toISOString()
  //     const notice = new Notification(notify.id, new Date().toISOString());
  //     this.ovAutoService.update(Notification, this.defaultApi, notice).then((res) => {
  //         this.notificationCount.emit(this.notifications.length);
  //       }).catch((ex) => {
  //         console.log('Ex: ', ex)
  //     });
  // }

  public markAllAsRead() {
    this.apollo.use(this.defaultApi).query({
      query: gql(`query markAllAsRead {
        markAllAsRead
      }`)
    }).subscribe(() => {
      this.notifications.forEach(notice => notice.readDate = new Date().toISOString());
      this.notificationCount.emit(0);
    })
  }

  public markVisibleAllAsRead() {
    const ids: number[] = [];
    this.notifications.forEach(notice => {ids.push(notice.id)});
    if(ids.length > 0){
      this.apollo.use(this.defaultApi).query({
        query: gql(`query markVisibleAllAsRead($numberParams: NumberArrayInput) {
        markVisibleAllAsRead(numberParams: $numberParams)
      }`),variables: { numberParams: {numbers: ids} }
      }).subscribe(() => {
        this.getNotifications();
      })
    }
  }

  getNotificationAge(notify: Notification) {
    const diff = moment(new Date).diff(moment(notify.createDate), 'hours')
    if(diff < 1){
      return 'Just now';
    } else if(diff < 24){
      return diff + (diff > 1 ? ' hours ago' : ' hour ago');
    }else{
      return moment.duration(moment(notify.createDate).diff(new Date)).humanize() + ' ago';
    }
  }

  getClass(notify: Notification) {
    if(notify.readDate !== null){
      return 'read'
    }else {
      return ''
    }
  }

  getLink(notice: Notification){
    const webSegment = notice.link.split(':')[0];
    const link = notice.link.split(':')[1];
    window.location.assign(environment.webUrl[webSegment] + link);
  }

  toggle() {
    console.log('closing')
    this.isOpen = !this.isOpen;
    if (!this.isOpen) {
      console.log('closingwswsw')
      this.close();
    }
  }

  outOfFocusClick() {
    this.isOpen = false;
    this.close();
  }

  close() {
    this.isClosed.emit(false);
  }

}
