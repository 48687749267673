<div class="main-content">
  <div class="d-flex p-4 justify-content-between">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header mx-3">
          <h5 class="title">Edit Profile</h5>
        </div>
        <div class="card-body">
          <ov-suite-form #form (formChange)="detectChanges($event)" [formClass]="profileForm" [data]="userData" [showSave]="false">
          </ov-suite-form>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-user">
        <div class="image"></div>
        <div class="card-body">
          <div class="author">
            <div class="fileinput fileinput-new text-center" data-provides="fileinput">
              <div class="fileinput-new thumbnail img-circle">
                <img id="uploadedImage" *ngIf="!!cognitoUser?.avatarUrl" [src]="cognitoUser.avatarUrl" alt="..." />
                <img id="uploadedImage" *ngIf="!cognitoUser?.avatarUrl" src="./assets/img/placeholder.jpg" alt="..." />
              </div>
              <div class="fileinput-preview fileinput-exists thumbnail img-circle"></div>
              <div>
                <span class="btn btn-round btn-rose btn-file">
                  <span *ngIf="!cognitoUser?.avatarUrl && !loading" class="fileinput-new">Add Photo</span>
                  <span *ngIf="!!cognitoUser?.avatarUrl && !loading" class="fileinput-new">Change Photo</span>
                  <span *ngIf="loading" class="fileinput-new">Uploading Image, please wait...</span>
                  <input type="file" name="..." (change)="onFileUpload($event)" />
                </span>
                <br />
                <a class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput"
                  ><i aria-hidden="true" class="fa fa-times"></i> Remove</a
                >
                <br />
                <h5 class="title">
                  {{ cognitoUser.name + ' ' + cognitoUser.familyName }}
                </h5>
              </div>
            </div>
            <hr />
            <div>
              <h6>Domains</h6>
              <p *ngFor="let domain of domainList">{{ domain.name }} {{ currentDomainId == domain.id ? '(current)' : '' }}</p>
              <p *ngIf="!domainList.length">none</p>
            </div>
            <div class="row" *ngIf="canUpdateProfile">
              <div class="col-md-12">
                <hr />
                <button
                  [disabled]="loading"
                  (click)="onSave()"
                  [ngClass]="detect.newChanges.length > 0 ? 'btn-success' : 'btn-default'"
                  class="btn btn-round btn-block"
                >
                  SAVE & UPDATE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
