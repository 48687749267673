<div class="row">
  <div class="col-12">
<!--    <label [for]="id + 'address'">-->
<!--      {{ title }}-->
<!--      <span aria-hidden="true" class="fa fa-info-circle" ngbTooltip="Add an address to set the location pin"></span>-->
<!--    </label>-->
    <div
      class="input-parent"
      [ngClass]="{
        'input-parent-disabled': disabled,
        'border-danger': danger
      }"
    >
      <input
        #mapSearch
        type="text"
        class="form-control"
        placeholder="Search Nearest Location"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
        [(ngModel)]="address"
      />
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-6">
        <label [for]="id + 'long'"
          >Longitude
          <span aria-hidden="true" class="fa fa-info-circle" ngbTooltip="Longitude coordinates"></span>
        </label>
        <div
          class="input-parent"
          [ngClass]="{
            'input-parent-disabled': disabled,
            'border-danger': danger
          }"
        >
          <input
            [id]="id + 'long'"
            type="number"
            class="form-control"
            [(ngModel)]="longitude"
            (ngModelChange)="locationChange()"
            placeholder="generated"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
          />
        </div>
      </div>
      <div class="col-6">
        <label [for]="id + 'lat'"
          >Latitude
          <span aria-hidden="true" class="fa fa-info-circle" ngbTooltip="Latitude coordinates"></span>
        </label>
        <div
          class="input-parent"
          [ngClass]="{
            'input-parent-disabled': disabled,
            'border-danger': danger
          }"
        >
          <input
            [id]="id + 'lat'"
            type="number"
            class="form-control"
            [(ngModel)]="latitude"
            (ngModelChange)="locationChange()"
            placeholder="generated"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="off"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <label>
      Map
      <span
        aria-hidden="true"
        class="fa fa-info-circle"
        ngbTooltip="Please search for a location and drag on the pin to move around if needed."
      ></span>
    </label>
    <div
      style="height: 400px"
      leaflet
      [leafletOptions]="options"
      [(leafletZoom)]="zoom"
      [(leafletCenter)]="center"
      [leafletLayer]="pin"
    ></div>
  </div>
</div>
