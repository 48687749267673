import { OVField, OVEntity } from '@ov-suite/ov-metadata';
import { PriceListModel } from './price-list.model';
import { ProductSku } from './product-sku.model';

@OVEntity({ name: 'ProductSkuPrice', tableName: 'product_sku_price' })
export class ProductSkuPriceModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: () => PriceListModel })
  priceList: PriceListModel;

  @OVField({ type: 'number' })
  exclPrice: string;

  @OVField({ type: 'number' })
  inclPrice: string;

  @OVField({ type: () => ProductSku })
  productSku: ProductSku;
}
