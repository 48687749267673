import { FieldValidator } from '@ov-suite/helpers-angular';
import { Profile } from '@ov-suite/models-idm';

export class PasswordValidator implements FieldValidator<Profile> {
  errorMessage = 'Password does not match.';

  async validate(profile: Profile): Promise<Boolean> {
    return profile.newPassword === profile.confirmPassword;
  }
}
